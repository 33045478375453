import React, { useEffect, useRef, useState } from 'react'
import DashboardStyle from '../Dashboard'
import { useNavigate } from 'react-router-dom'
import api, { customFuncsApi } from '../../../api'
import { get } from 'lodash'
import ClipLoader from 'react-spinners/ClipLoader'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { Empty } from 'antd'
import InfoByWhsModal from './WhsModal'
import InfoByCityModal from './CityInfoModal'
import { useTranslation } from 'react-i18next'

export default function Tab2() {
	const navigation = useNavigate()
	const { getMe } = useSelector((state) => state.main)
	const dateFormat = 'YYYY-MM-DD'

	const errorRef = useRef()
	const infoByWhsRef = useRef()
	const infoByRegion = useRef()
	const { t } = useTranslation()

	const [isLoading, setIsLoading] = useState({
		loadingDebitCredit: false,
		loadingPurchases: false,
		loadingSold: false,
	})
	const [startDate, setStartDate] = useState(
		dayjs('2010-01-01').format('YYYY-MM-DD'),
	)
	const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'))
	const [data, setData] = useState({
		data: [],
	})
	const [pageSold, setPageSold] = useState(0)
	const [pagePurchased, setPagePurchased] = useState(0)
	const [purchasedData, setPurchasedData] = useState({
		data: [],
		currentPage: 0,
	})
	const [dailyAccountStats, setDailyAccountStats] = useState([])
	const [balanceMoney, setBalanceMoney] = useState([])
	const [balanceLoading, setBalanceLoading] = useState(false)

	const fetchDailyAccountStats = async () => {
		setIsLoading((prev) => ({ ...prev, loadingDebitCredit: true }))
		const url =
			get(getMe, 'Department2.Name', '') === 'Sotuv' ||
			get(getMe, 'Department2.Name', '') === 'Cashier'
				? `SQLQueries(%27getDebitCreditSum%27)/List?date1='${startDate + ' 00:00:00.000Z'}'&date2='${endDate + ' 00:00:00.000Z'}'&account1='${get(getMe, 'U_CardAccount', '')}'&account2='${get(getMe, 'U_CashAccount', '')}'&account3='${get(getMe, 'U_CashUzsAccount', '')}'&account4='${get(getMe, 'U_TerminalAccount', '')}'&account5='${get(getMe, 'U_TransAccount', '')}'`
				: `SQLQueries(%27getDebitCreditSum%27)/List?date1='${startDate + ' 00:00:00.000Z'}'&date2='${endDate + ' 00:00:00.000Z'}'`

		api(url)
			.then((res) => {
				setDailyAccountStats(JSON.parse(res.data).value[0])
			})
			.finally(() => {
				setIsLoading((prev) => ({ ...prev, loadingDebitCredit: false }))
			})
	}

	const sold = () => {
		setIsLoading((prev) => ({ ...prev, loadingSold: true }))
		customFuncsApi
			.get(`xsSql/getDunningProblemStatsByPlace`)
			.then((res) => {
				const resData = res.data.value
				setData({ data: [...resData] })
			})
			.catch((err) => {
				// errorRef.current?.open(
				//   get(JSON.parse(err.response.data), 'error.message', ''),
				// )
			})
			.finally(() => {
				setIsLoading((prev) => ({ ...prev, loadingSold: false }))
			})
	}

	const BalanceMoney = async () => {
		setBalanceLoading(true)
		let url =
			get(getMe, 'Department2.Name', '') === 'Sotuv' ||
			get(getMe, 'Department2.Name', '') === 'Cashier'
				? `ChartOfAccounts?$filter=Code eq '${get(getMe, 'U_CardAccount', '')}' or Code eq '${get(getMe, 'U_CashAccount', '')}' or Code eq '${get(getMe, 'U_CashUzsAccount', '')}' or Code eq '${get(getMe, 'U_TerminalAccount', '')}' or Code eq '${get(getMe, 'U_TransAccount', '')}' and  ExternalCode eq '1'&$select=Code,Name,Balance,Balance_syscurr,Balance_FrgnCurr,AcctCurrency`
				: `ChartOfAccounts?$filter=startswith(Code, '50') and ExternalCode eq '1'&$select=Code,Name,Balance,Balance_syscurr,Balance_FrgnCurr,AcctCurrency`

		const data = await api
			.get(url, {
				headers: {
					Prefer: 'odata.maxpagesize=10000',
				},
			})
			.then((res) => JSON.parse(res.data).value)
		setBalanceMoney(data)
		setBalanceLoading(false)
	}

	useEffect(() => {
		BalanceMoney()
	}, [])

	const purchased = () => {
		setIsLoading((prev) => ({ ...prev, loadingPurchases: true }))
		// ?date1='${startDate + ' 00:00:00.000Z'}'&date2='${endDate + ' 00:00:00.000Z'}'&$skip=${pagePurchased}${get(getMe, 'Department2.Name', '') === 'Sotuv' ? `&WhsCode='${get(getMe, 'U_Warehouse', '')}'` : ''}
		customFuncsApi
			.get(`xsSql/getDunningProblemStatsByWhs`)
			.then((res) => {
				const resData = res.data.value
				setPurchasedData({ data: [...resData] })
			})
			.catch((err) => {
				// errorRef.current?.open(
				//   get(JSON.parse(err.response.data), 'error.message', ''),
				// )
			})
			.finally(() => {
				setIsLoading((prev) => ({ ...prev, loadingPurchases: false }))
			})
	}

	useEffect(() => {
		fetchDailyAccountStats()
	}, [startDate, endDate])

	useEffect(() => {
		purchased()
	}, [startDate, endDate, pagePurchased])

	useEffect(() => {
		sold()
	}, [pageSold, startDate, endDate])

	return (
		<DashboardStyle>
			<div className="container">
				<div>
					<h4 className={'font-bold text-lg text-center mt-10 mb-4'}>
						{t('reportsByRegion')}
					</h4>
					{isLoading.loadingSold ? (
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							<ClipLoader
								loading={true}
								size={20}
								aria-label="Loading Spinner"
								className={'loader'}
								data-testid="loader"
							/>
						</div>
					) : (
						<div className="overflow-y-auto mb-8 mt-8">
							<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
								<thead className="text-xs text-gray-700 uppercase bg-gray-50  ">
									<tr>
										<th scope="col" className="px-6 py-4">
											{t('Region')}
										</th>

										<th scope="col" className="px-6 py-4">
											{t('quantity')}
										</th>
									</tr>
								</thead>
								<tbody>
									{data.data.length > 0 ? (
										data.data.map((v, i) => {
											return (
												<tr
													key={i}
													className="bg-white border-b  hover:bg-gray-50 "
													onClick={() => {
														infoByRegion?.current?.open({
															Code: v.Code,
															Name: v.Name,
														})
													}}
												>
													<td
														scope="row"
														className="px-6 py-4 font-medium text-gray-900 "
													>
														{v.Name}
													</td>
													<td className="px-6 py-4">{v.Count}</td>
												</tr>
											)
										})
									) : (
										<tr>
											<td colSpan="10">
												<Empty
													description={t('noInfo')}
													image={Empty.PRESENTED_IMAGE_SIMPLE}
												/>
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
					)}
				</div>

				<div>
					<h4 className={'font-bold text-lg text-center mt-10 mb-4'}>
						{t('reportsByWhs')}
					</h4>
					{isLoading.loadingPurchases ? (
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							<ClipLoader
								loading={true}
								size={20}
								aria-label="Loading Spinner"
								className={'loader'}
								data-testid="loader"
							/>
						</div>
					) : (
						<div className="overflow-y-auto mb-8 mt-8">
							<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
								<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
									<tr>
										<th scope="col" className="px-6 py-4">
											{t('status')}
										</th>

										<th scope="col" className="px-6 py-4">
											{t('quantity')}
										</th>
									</tr>
								</thead>
								<tbody>
									{purchasedData.data.length > 0 ? (
										purchasedData.data.map((v, i) => {
											return (
												<tr
													key={i}
													className="bg-white border-b  hover:bg-gray-50 "
													onClick={() => {
														infoByWhsRef?.current?.open(v.FldValue)
													}}
												>
													<td className="px-6 py-4 font-medium text-gray-900 ">
														{v.Descr}
													</td>

													<td className="px-6 py-4">{v.Count}</td>
												</tr>
											)
										})
									) : (
										<tr>
											<td colSpan="10">
												<Empty
													description={t('noInfo')}
													image={Empty.PRESENTED_IMAGE_SIMPLE}
												/>
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
					)}
				</div>
			</div>
			<InfoByWhsModal getRef={(r) => (infoByWhsRef.current = r)} />
			<InfoByCityModal getRef={(r) => (infoByRegion.current = r)} />
		</DashboardStyle>
	)
}
