import React from 'react'
import { useTranslation } from 'react-i18next'
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons'

const RenderTableHeader = ({
	title,
	sortColumn,
	handleSort,
	sortConfig,
	isSortable = false,
	padding = 'px-3 py-4',
	className,
	textAlign = 'text-center',
}) => {
	const { t } = useTranslation()
	return (
		<th
			scope="col"
			className={`cursor-pointer ${padding} ${className} ${textAlign}`}
			onClick={() => {
				if (sortColumn) handleSort(sortColumn)
			}}
		>
			{sortColumn && isSortable ? (
				<div className="flex items-center gap-1 ">
					{t(title)}
					<div>
						{sortConfig.column === sortColumn ? (
							sortConfig.direction === 'ASC' ? (
								<ArrowUpOutlined />
							) : sortConfig.direction === 'DESC' ? (
								<ArrowDownOutlined />
							) : (
								<span className="flex  items-start justify-start">
									<ArrowUpOutlined />
									<ArrowDownOutlined />
								</span>
							)
						) : (
							<span className="flex  items-start justify-start">
								<ArrowUpOutlined />
								<ArrowDownOutlined />
							</span>
						)}
					</div>
				</div>
			) : (
				t(title)
			)}
		</th>
	)
}

export default RenderTableHeader
