import React, { useEffect, useRef, useState } from 'react'
import { ErrorModal } from '../../components/Modal'
import { useLocation, useParams } from 'react-router-dom'
import Button from '../../components/Button'
import Layout from '../../components/Layout'
import DownloadStyle from './DownloadStyle'
import writtenNumber from 'written-number'
import DollarToWord from './DollarToWord'
import logo from '../../assets/images/logo.png'
import html2PDF from 'jspdf-html2canvas'
import { get } from 'lodash'
import api from '../../api'
import moment from 'moment'
import { useSelector } from 'react-redux'
import formatPhoneNumber from '../../helpers/PhoneWithSpaces'

const Download = () => {
	const { getMe } = useSelector((state) => state.main)

	const location = useLocation()
	const errorRef = useRef()
	const dataLocation = get(location, 'state.data', {})
	const [userData, setuserData] = useState({})
	const [paymentDates, setPaymentDates] = useState([])
	const [item, setItem] = useState()
	const [dollar, setDollar] = useState()
	const [invoice, setInvoice] = useState()
	const [userFields, setUserFields] = useState([])
	const [userFieldsOITB, setUserFieldsOITB] = useState([])
	const [whsData, setWhsData] = useState([])

	const whsNum = (whsCode) => {
		switch (whsCode) {
			case '01':
				return '+998	71 200 00 00'
			case '02':
				return '+998	71 200 00 00'
			case '03':
				return '+998	71 200 00 00'
			case '04':
				return '+998	71 200 00 00'

			default:
				break
		}
	}

	useEffect(() => {
		getUser()
		getInformationOfItem()
		getInfoOFDevice()
		userFieldsFn()
		userFieldsOITBFn()
		getWhs()
	}, [])

	useEffect(() => {
		getUSD(paymentDates)
	}, [paymentDates])

	const userFieldsFn = () => {
		api
			.get('UserFieldsMD', {
				params: {
					$filter: "TableName eq 'OITM' ",
				},
			})
			.then((res) => {
				setUserFields(JSON.parse(res.data).value)
			})
	}

	const userFieldsOITBFn = () => {
		api
			.get('UserFieldsMD', {
				params: {
					$filter: "TableName eq 'OINV' ",
				},
			})
			.then((res) => {
				setUserFieldsOITB(JSON.parse(res.data).value)
			})
	}

	const formatPhoneNumber4 = (phoneNum) => {
		// Split the phoneNum by ',' and '"' and remove empty entries
		const parts = phoneNum.split(/[,]/).filter(Boolean)

		return parts.length > 4 ? parts.slice(0, 4).join(', ') : parts.join(', ')
	}

	const getInformationOfItem = () => {
		// setIsLoading(true)
		api
			.get(
				`SQLQueries('getInstallments')/List?docEntry='${location.state.data.DocEntry}'`,
				{
					headers: {
						Prefer: 'odata.maxpagesize=50',
					},
				},
			)
			.then((res) => {
				const resData = get(JSON.parse(res.data), 'value', [])
				setPaymentDates(resData)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
		// setIsLoading(false)
	}

	const getUser = () => {
		api
			.get(
				`BusinessPartners('${get(
					dataLocation,
					'CardCode',
					0,
				)}')?$select=CardCode,CardName,U_Telephone,Address,Phone1,Phone2,U_Gender,U_PS,BPAddresses,Fax`,
			)
			.then((res) => {
				const resData = JSON.parse(res.data)
				setuserData(resData)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}

	const getWhs = () => {
		api
			.get(`Warehouses`)
			.then((res) => {
				const resData = JSON.parse(res.data).value
				setWhsData(resData)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}

	const getUSD = (paymentDates) => {
		api
			.get(
				`SQLQueries('getUSDRate')/List?date='${get(
					paymentDates[0],
					'DueDate',
					'',
				)}'`,
			)
			.then((res) => {
				const resData = get(JSON.parse(res.data), 'value', [])
				setDollar(get(resData, '[0].Rate', 0))
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}

	const download = () => {
		let element = document.getElementsByClassName('contract')

		html2PDF(element, {
			margin: 10,
			filename: 'myfile.pdf',
			image: { type: 'jpeg', quality: 0.98 },
			html2canvas: { scale: 2, logging: true, dpi: 150, letterRendering: true },
			jsPDF: {
				unit: 'mm',
				format: 'a4',
				orientation: 'portrait',
				fontSize: 20,
			},
		})
	}

	const getInfoOFDevice = () => {
		api
			.get(`Invoices(${location.state.data.DocEntry})`)
			.then((res) => {
				const resData = get(JSON.parse(res.data), 'DocumentLines[0]', [])
				setInvoice(JSON.parse(res.data))

				getItems(get(resData, 'ItemCode', ''))
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}

	const getItems = (code) => {
		api
			.get(`Items('${code}')`)
			.then((res) => {
				const resDate = JSON.parse(res.data)
				setItem(resDate)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}

	const telephoneNum = userData?.U_Telephone?.includes(',')
		? userData?.U_Telephone?.split(',')
		: userData?.U_Telephone

	const color =
		get(item, 'U_Color', '') !== null
			? userFields
					?.find((item) => item.Name === 'Color')
					?.ValidValuesMD.find(
						(value) => get(item, 'U_Color', '') === value.Value,
					)?.Description || ''
			: ''

	return (
		<Layout>
			<DownloadStyle>
				<div className="flex justify-end">
					<Button
						onClick={download}
						btnStyle={{ marginBottom: 10, width: 200 }}
					>
						Загрузить договор
					</Button>
				</div>
				<div className="contract shadow-md">
					<h2 className="text-center">
						<b>TILXAT</b>
					</h2>
					<div className="contract-content">
						<p>
							<span className="ml-8">Men</span>{' '}
							<span className="font-bold">{userData.CardName}</span> sotuvchi
							Abitov Murod Mansurovichdan Telefon turi:{' '}
							<span className="font-bold">
								{item
									? `${`${item?.ItemName} ${color ?? ''} ${item?.U_Memory ?? ''} modeldagi , `}`
									: '{_____________________________'}
							</span>
							IMEI kodi:{' '}
							<span className="font-bold">
								{invoice?.DocumentLines[0]?.SerialNumbers[0]
									?.InternalSerialNumber
									? invoice.DocumentLines[0].SerialNumbers[0]
											.InternalSerialNumber
									: '_______________'}
							</span>
							, bo’lgan telefonni pulini mudattli to’lov sharti bilan sotib
							oldim. Telefonning umumiy narxi{' '}
							<span className="font-bold">
								{new Intl.NumberFormat('fr-FR').format(invoice?.DocTotal) +
									'  '}
							</span>
							dollari miqdorida bolib shundan{' '}
							<span className="font-bold">
								{new Intl.NumberFormat('fr-FR').format(
									paymentDates[0]?.InsTotal,
								)}{' '}
							</span>
							AQSH dollari miqdoridagi pulni SOTUV KURSI bo’yicha{' '}
							<span className="font-bold">
								{new Intl.NumberFormat('fr-FR').format(
									paymentDates[0]?.InsTotal * dollar,
								) + '  '}
							</span>
							so’mda oldindan to’ladim.Qolgan {'  '}
							<span className="font-bold">
								{invoice?.DocTotal - paymentDates[0]?.InsTotal}
							</span>{' '}
							AQSH dollari miqdoridagi pulni{' '}
							<span className="font-bold">
								{paymentDates ? paymentDates.length - 1 : '________'} oy
							</span>{' '}
							davomida har oyning{' '}
							<span className="font-bold">
								{paymentDates
									? moment(paymentDates[0]?.DueDate).format('DD')
									: '________'}
							</span>
							-sanasida{' '}
							<span className="font-bold">
								{paymentDates ? paymentDates[1]?.InsTotal : '________'}
							</span>{' '}
							AQSH dollari miqdoridagi pulni tijorat banklari sotuv kursi
							bo’yicha so’mda sotuvchi aytgan joyga va o’z vaqtidan kechiktirmay
							to’lab borilishini o’zim ta’minlayman. __________(imzo)
						</p>
						<p>
							<span className="ml-8">Xaridor </span>
							shaxsiy ma'lumotlarni qayta ishlashga, shuningdek qarzdorlik yoki
							qarzni to'lamaslik holatida shaxsiy identifikatsion raqami
							bo'yicha topilgan barcha mavjud va yangi ochiladigan to'lov
							kartalaridan (Uzcard/Humo va Kobeyj kartalari) qarz mablag'larini
							echib olishga o'z roziligini beradi________(imzo).
						</p>
						{/* {invoice.DocTotalSys} */}
						<p>
							<span className="ml-8">Oylik</span>
							{'  '}to’lovlarni o’z vaqtida to’liq to’lab berolmagan taqdirimda,
							telefonni sotuvchining birinchi talabidayoq bus-butun holda, hech
							qanday buzilishlarsiz o’zim qaytarib beraman. Telefonni sotuvchiga
							qaytarish qarzdorlik to’liq qoplanadi degani emas. Telefon
							sotuvchiga qaytarilgan vaqtida uning o’sha vaqtdagi holatiga qarab
							baholanadi va sotiladi. Shunda ham tilxatda ko’rsatilgan qarzim
							to’liq qoplanmasa, qolgan qarzdorlikni o’z vaqtidan kechiktirmay
							to’liq to’lab berishga majburman. Oylik to’lovlar muddatidan oldin
							to’liq to’langan taqdirda yoki muddatidan kechiktirilgan taqdirda
							ham tilxatda kelishilgan umumiy narx miqdori o’zgarmasligi haqida
							ogohlantirildim va shunga roziman. ____________(imzo)
						</p>

						<p>
							<span className="ml-8">Ushbu</span> tilxat bilan men muddatli
							to’lov asosida sotib olayotgan qurilmaga sotuvchi tomonidan
							qulflash tizimi o’rnatilishiga roziligimni bildiraman. O’rnatilgan
							tizim orqali qurilmaga va berilgan telefon raqamlariga oylik
							to’lovlar va qarzdorliklar haqidagi sms xabarlar jo’natilishiga
							roziman. Oylik to’lovni o’z vaqtidan 5 ish kuni kechiktirsam yoki
							oylik to’lovni to’liq to’lamasam sotuvchi ushbu qurilmani
							masofadan qulflashiga roziman.
							<span className="font-bold">
								Qurilma masofadan qulflanganida qurilma ichidagi barcha
								ma’lumotlar o’chib ketishi mumkinligi haqida sotuvchi tomonidan
								ogohlantirildim va shunga roziman
							</span>
							{'  '}____________(imzo).
						</p>
						<p>
							<span className="ml-8">Qurilmaga</span> tegishli kafolat taloni
							bilan to’liq tanishib chiqdim va shu kafolat shartlari asosida
							xarid qilishga roziman. ____________(imzo)
						</p>
						<p>
							<span className="ml-8">Qurilma</span> qutisi va xujjatlari
							sotuvchida kafolat sifatida qarzdorlik to’liq to’languniga qadar
							saqlanadi. Qurilma uchun qarzdorlik to’liq to’lab bo’linganidan
							so’ng, qurilma qutisi va xujjatlari oxirgi to’lov qilinganidan
							so’ng 5 ish kuni ichida xarid amalga oshirilgan do’konga yoki
							xaridor bilan kelishilgan holda boshqa filialga yetkazib beriladi.
							Agar 5 ish kuni ichida kelishilgan do’konga yetkazib berilmasa,
							sotuvchi xaridorga qutini yetkazib berishga majbur. Sotuvchi
							texnika uchun qarzlar to’liq to’langan kundan boshlab 1 oy
							mobaynida qutini saqlashni zimmasiga oladi va 1 oydan keyin saqlab
							berishga majbur emas. O’z vaqtida olib ketilmagan texnika qutilari
							tashlab yuborilishi haqida sotuvchi tomonidan ogohlantirildim.
							____________(imzo)
						</p>

						<h1 className="text-center mt-10">
							<b>ESLATMA</b>
						</h1>
						<p>
							<b>1.</b>
							{'  '}Sotib olinayotgan qurilma do’kondan chiqib ketguniga qadar
							xaridor tarafidan to’liq tekshirib olinishi shart. Biror jismoniy
							nuqsoni (masalan ekran yoki korpusdagi qirilish, dog’) bor bo’lsa,
							do’kondan chiqmasdan turib sotuvchiga xabar berilishi kerak.
							Qurilma do’kondan olib chiqib ketilganidan keyin yuqoridagiga
							o’xshash biror jismoniy nuqson bilan qaytarib olib kelinsa, bunga
							sotuvchi javobgar emas.
						</p>
						<p>
							<b>2.</b>
							{'  '}Telefon sotib olinganidan keyin xaridorga yoqmay qolganligi
							uchun telefonni qaytarib olishga yoki boshqasiga almashtirib
							berishga sotuvchi majbur emas. Mijoz tilxatni imzolanganidan keyin
							muddat va narx o’zgartirilmaydi.
						</p>
						<div className="mt-5">
							<p>
								<b>3.</b>
								{'  '}Telefonga jismoniy zararlar yetkazilishi, undan noto’g’ri
								foydalanish, o’zboshimchalik bilan servis ustalaridan boshqa
								ustalar ta’mirlashi tufayli ishdan chiqsa (bu holatda kafolat
								amal qilmaydi), yoqotib qo’yilsa, o’g’irlab ketilsa ham xaridor
								tilxatda ko’rsatilgan muddatda belgilangan to’lovlarni o’z
								vaqtida, to’liq to’lashga majbur.
							</p>
						</div>

						<p>
							<b>4.</b>
							{'  '}Sotuvchi tomonidan mobil telefonlarga qo’yib berilgan mahsus
							tizimlar (iCloud, Samsung Account, MI account) xaridor tomonidan
							buzishga harakat qilinsa yoki biror kirgazilgan ma’lmot
							o’zgartirilsa sotuvchi bu tizimni mobil telefondan chiqarib berish
							javobgarligidan ozod bo’ladi. Bunday holatda xaridor o’zi javobgar
							bo’ladi.
						</p>
						<p>
							<span className="ml-8">Ushbu</span> tilxatning barcha shartlarini
							biror fors-major holati bo’lgan taqdirda ham, karantin sababli ham
							bajarishga roziman. Bu holatlar mening oylik to’lovlarimni o’z
							vaqtida to’lashimga to’sqinlik qilmasligini to’liq kafolatlayman.
						</p>
						<div className="flex w-full mt-10 font-bold">
							<hr className="w-full border-t font-bold border-black" />
						</div>

						<div className="flex w-full mt-10 font-bold">
							<hr className="w-[80%] border-t font-bold border-black" />
						</div>

						<div className="ml-[5px] mt-10 flex items-center justify-between">
							<div className="flex flex-col gap-3">
								<p>
									<b>Xaridor tel raqami:</b>
								</p>
								<div>
									{Array.isArray(telephoneNum) ? (
										telephoneNum.slice(0, 4).map((num, index) => (
											<p key={index}>
												<b>
													{index + 1}.{' '}
													{formatPhoneNumber(formatPhoneNumber4(num))}
												</b>
											</p>
										))
									) : (
										<p>
											<b>1. {formatPhoneNumber(telephoneNum)}</b>
										</p>
									)}
								</div>
							</div>

							<div className="flex flex-col gap-2">
								<p>
									<b>
										Sana:
										{paymentDates
											? moment(paymentDates[0]?.DueDate).format('DD.MM.YYYY')
											: '________'}
									</b>
								</p>
								<p>
									<b> Imzo: _____________</b>
								</p>
							</div>
						</div>
						<div className="flex items-center justify-between mt-10">
							<div> </div>
							<div>
								<img src={logo} className="w-[150px]" />
							</div>
						</div>

						<div className="mt-[100px]">
							<p className="text-center font-extrabold	text-base py-3">
								KAFOLAT TALONI
							</p>
						</div>
						<p>
							<b>
								QURILMA TURI:{' '}
								{item?.U_Group
									? item?.U_Group
									: '__________________________________'}
							</b>
						</p>

						<p>
							<b>
								MODEL:{'    '}
								{item?.ItemName
									? item?.ItemName
									: '__________________________________'}
							</b>
						</p>
						<p>
							<b>
								TELEFON HOLATI:{'    '}
								{item?.U_Condition
									? item?.U_Condition
									: '__________________________________'}
							</b>
						</p>
						<p>
							<b>
								SERIA RAQAMI:{'    '}
								{invoice?.DocumentLines[0]?.SerialNumbers[0]
									?.InternalSerialNumber
									? invoice.DocumentLines[0].SerialNumbers[0]
											.InternalSerialNumber
									: '_______________'}
							</b>
						</p>
						<p>
							<b>
								XARID QILINGAN DO’KON:{'    '}
								{invoice?.DocumentLines[0]?.WarehouseCode
									? whsData?.find(
											(whs) =>
												whs.WarehouseCode ===
												invoice.DocumentLines[0].WarehouseCode,
										)?.WarehouseName
									: '_______________'}
							</b>
						</p>
						<p>
							<b>
								XARID QILINGAN SANA:{'    '}
								{paymentDates
									? moment(paymentDates[0]?.DueDate).format('DD.MM.YYYY')
									: '________'}
							</b>
						</p>

						<p>
							<b>
								DO’KON TELEFON RAQAMI:{'    '}
								{invoice?.DocumentLines[0]?.WarehouseCode
									? whsData?.find(
											(whs) =>
												whs.WarehouseCode ===
												invoice.DocumentLines[0].WarehouseCode,
										)?.U_Branch_Number
									: '_______________'}
							</b>
						</p>

						<h1 className="text-center mt-3">
							<b>KAFOLAT MAJBURIYATLARI VA BEPUL XIZMAT KO’RSATISH SHARTLARI</b>
						</h1>

						<p>
							<b>1.</b> Kafolat bo‘yicha ta’mirlatish uchun, avvalo, shu
							qurilmangiz {' '}
							<b>
								CredoMobileUz do’konlaridan biridan xarid qilingan bo‘lishi
								shart.
							</b>{' '}
							Buning dalili sifatida siz 
							<b>gadjetning qutisi va xujjatlarini,</b>
							shuningdek, uni sotib olgan paytingizda do‘kondan olingan 
							<b>kafolat talonini</b> taqdim etishingiz lozim. Boshqa
							do’konlardan olingan texnikalar uchun CredoMobileUz kafolat
							bermaydi.
						</p>
						<p>
							<b>2.</b> Gadjetning <b>ichi ochilmagan bo‘lishi shart.</b> Buni
							bilish unchalik qiyin emas – har bir qurilmaning hali
							ochilmaganligini bildiruvchi plombalari mavjud.
						</p>
						<div className="mt-8">
							<p>
								<b>3.</b> Agarda smartfon yoki boshqa qurilmangizning 
								<b>tashqi nuqsoni bo‘lsa,</b>
								unga servis xizmati ko‘rsatishmaydi. Deylik, telefoningiz
								ekranining bir burchagida ozgina qirilgan yoki pachoq bo’lgan iz
								bor. Agarda shu holida uning boshqa bir qismi, masalan, kamerasi
								yaxshi ishlamayotgan bo‘lsa ham, CredoMobile unga kafolat
								xizmati ko‘rsatishmaydi. Sababi oddiy – ekrandagi qirilish
								smartfonni siz yerga tushirib yuborganingiz yoki qattiqroq biror
								narsaga urib olganingizni anglatadi. O‘z-o‘zidan ma’lumki,
								kamerasi ham o‘shanda buzilib qolgan – unga servis xizmati
								ko‘rsatishni esa ishlab chiqaruvchi o‘z zimmasiga olmaydi.
							</p>
						</div>
						<p>
							<b>4.</b> Qurilmaning dasturiy ta’minotiga nolegal o‘zgartirish
							kiritilmagan bo‘lishi lozim. Masalan, 
							<b>smartfonga root huquqi olingan bo‘lsa,</b> ishlab chiqaruvchi
							unga servis xizmati ko‘rsatishni rad etishga haqli. Zero, shu kabi
							o‘zgartirishlar qurilmaning ayrim qismlari yoki operatsion tizimi
							ishdan chiqishiga olib keladi va bunday hollar uchun CredoMobile
							mas’ul bo‘lmaydi. Beta versiyadagi operatsion tizimlar o’rnatilgan
							qurilmaga ham kafolat berilmaydi.
						</p>
						<p>
							<b>5.</b> Xarid qilingan telefon uchun sotib olingan kundan
							boshlab 3 kun muddat ichida biror texnik nuqsonlar (xaridorning
							ta’sirisiz) kelib chiqqan taqdirda boshqa xuddi shunday telefonga
							almashtirib berish yo’li bilan kafolat beriladi. Xarid qilinganiga
							3 kundan ortiq vaqt o’tib CredoMobile ga nuqson bilan olib
							borilgan hech bir 
							<b>
								qurilmaning o‘zi yangisiga(boshqasiga) almashtirib berilmaydi,{' '}
							</b>
							balki uning nuqsonli qismi (kamera, ekran, USB-port yoki boshqa
							joyi) yangisiga almashtiriladi, xolos.
							<b>
								Bepul ta’mirlash xizmati xarid kunidan boshlab 100 kun ichida
								kelib chiqgan nuqsonlar uchun amal qiladi. Xarid qilinganiga 100
								kundan ortiq vaqt o’tgan hech bir qurilmada kelib chiqgan
								nuqsonlar uchun CredoMobile kafolat va bepul serviz xizmatini
								zimmasiga olmaydi.
							</b>
							Bunday holatda xaridor pullik servislarga murojaat qilishga haqli.
						</p>

						<p>
							<b>6.</b> Kafolat bo‘yicha servis xizmati ko‘rsatilishi uchun, siz
							nuqsonli, ammo yuqoridagi talablarga mos keluvchi 
							<b>
								qurilmangizni CredoMobile da qoldirib ketishingiz kerak bo‘ladi
							</b>
							. Ya’ni, uni shu zahotiyoq tuzatib yoki ishlamayotgan qismini
							almashtirib bera olinmaydi. Qancha muddatga qoldirish kerakligi
							esa gadjetning nuqsoni qay darajada ekanligidan kelib chiqib,
							ma’lum qilinadi.
						</p>
						<p>
							<b>7.</b> Sotilgan har bir mobil telefonning jismoniy sim karta
							slotlarining IMEI kodlari sotuvchi tomonidan UZIMEI ro’yhatidan
							o’tkazib beriladi. Jismoniy sim karta sloti bo’lmagan
							telefonlarning faqat bitta IMEI kodi UZIMEI dan ro’yhatdan
							o’tkazib beriladi. Ikkinchi sim karta sloti uchun IMEI kodlarini
							ro’yhatdan o’tkazishni sotuvchi o’z majburiyatiga olmaydi. Bunday
							IMEI kodlarni xaridor xohishiga ko’ra o’zi ro’yhatdan o’tkazib
							olishga haqli.
						</p>
						<div className="mt-10">
							<p className="font-bold">
								Kafolat shartlari bilan to’liq tanishib chiqdim va shu shartlar
								asosida xarid qilishga roziman. __________(imzo)
							</p>
						</div>
						<div className="flex w-full mt-12 font-bold">
							<hr className="w-full border-t font-bold border-black" />
						</div>
					</div>
				</div>
			</DownloadStyle>
			<ErrorModal
				getRef={(r) => {
					errorRef.current = r
				}}
			/>
		</Layout>
	)
}

export default Download
