import React, { useEffect, useRef, useState, useCallback } from 'react'
import CreatePurchases from './CreatePurchases'
import Button from '../../components/Button'
import { useNavigate } from 'react-router-dom'
import api from '../../api'
import { get } from 'lodash'
import _ from 'lodash'
import Layout from '../../components/Layout'
import { ErrorModal, SuccessModal } from '../../components/Modal'
import ClipLoader from 'react-spinners/ClipLoader'
import { isAxiosError } from 'axios'
import { useSelector } from 'react-redux'
import CreateSuppliersModal from '../../components/Modal/CreateSuppliers'
import customMainApi from '../../api/index'
import { DatePicker, AutoComplete, Tag } from 'antd'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

const CreatePurchasesPage = () => {
	const { getMe } = useSelector((state) => state.main)

	const succesCreateUserRef = useRef()
	const createUserRef = useRef()
	const { t } = useTranslation()
	const successModalRef = useRef()

	const navigate = useNavigate()
	const errorRef = useRef()
	const inputRef = useRef(null)
	const [businessPartnersData, setBusinessPartnersData] = useState([])
	const [itemsData, setItemsData] = useState([])
	const [selectedItem, setSelectedItem] = useState([])
	const [whichInput, setWhichInput] = useState(null)
	const [loadingBusinessPartners, setLoadingBusinessPartners] = useState(false)
	const [userFields, setUserFields] = useState([])
	const [condition, setCondition] = useState('')
	const [warehouseLoading, setWarehouseLoading] = useState(false)
	const [itemsLoading, setItemsLoading] = useState(false)
	const [warehouses, setWarehouses] = useState([])
	const [toWarehouse, setToWarehouse] = useState(
		get(getMe, 'Department2.Name', '') !== 'Boshqaruv'
			? get(getMe, 'U_Warehouse', '')
			: '',
	)
	const [form, setForm] = useState({
		CardCode: '',
		// DocDate: moment().format('YYYY-MM-DD') + 'T00:00:00',
		CardName: '',
		DocCurrency: 'USD',
		DocumentLines: [],
	})

	const [createUserLoading, setCreateUserLoading] = useState(false)
	const initItem = {
		ItemCode: '',
		Quantity: 0,
		UnitPrice: 0,
		SerialNumbers: '',
		U_IMEI_PURCH: 'IMEI',
		U_warranty: '',
		batteryLife: '',
		U_Item_Region: '',
	}
	const [items, setItems] = useState([
		{
			...initItem,
		},
	])
	const [itemsName, setItemsName] = useState([''])
	const [date, setDate] = useState(dayjs())
	const [cardName, setCardName] = useState('')
	const [cardCode, setCardCode] = useState('')
	const [btnLoading, setBtnLoading] = useState(false)

	const getBusinessPartners = async (name = '') => {
		const formattedName = name.includes("'") ? name.replace(/'/g, "''") : name

		setLoadingBusinessPartners(true)
		const data = await api(
			`BusinessPartners?$select=CardCode,CardName,GroupCode&$filter=CardType eq 'cSupplier' and GroupCode eq 101 and contains(CardName,'${formattedName}')`,
		).then((res) => {
			return JSON.parse(res.data).value
		})

		const selectElementData = data.map((item) => {
			return {
				value: item.CardCode,
				label: item.CardName,
			}
		})

		setBusinessPartnersData(selectElementData)
		setLoadingBusinessPartners(false)
	}

	const purchaseInvoicesPost = async () => {
		const uniqueItems = []
		items.forEach((item) => {
			{
				uniqueItems.push({
					ItemCode: item.ItemCode,
					Quantity: 1,
					SerialNumbers: [{ InternalSerialNumber: item.SerialNumbers }],
					UnitPrice: parseFloat(item.UnitPrice),
					U_IMEI_PURCH: item.U_IMEI_PURCH,
					U_Percent: item.batteryLife,
					U_warranty: Number(item.U_warranty),
					WarehouseCode: toWarehouse,
					U_Region_Item: item.U_Item_Region,
				})
			}
		})
		const formatedDate = {
			CardCode: cardCode,
			DocCurrency: form.DocCurrency,
			DocDate: dayjs(date).format('YYYY-MM-DD') + 'T00:00:00',
			SalesPersonCode: get(getMe, 'SalesPersonCode', -1),
			DocumentsOwner: get(getMe, 'EmployeeID', null),

			DocumentLines: uniqueItems,
		}

		setBtnLoading(true)

		try {
			await api.post(`PurchaseInvoices`, formatedDate).then((res) => {
				successModalRef.current?.open(t('sucUser'))

				return JSON.parse(res.data).value
			})
		} catch (e) {
			if (isAxiosError(e)) {
				errorRef.current?.open(
					get(JSON.parse(e.response.data), 'error.message', ''),
				)
			}
		} finally {
			setBtnLoading(false)
		}
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedSearchItem = useCallback(
		_.debounce((e, i) => {
			getItems(e.target.value, i)
		}, 1000),
		[],
	)
	const getItems = async (name = '', i) => {
		if (name.length <= 0) {
			setItemsData([])
			return
		}

		setItemsLoading(true)
		setWhichInput(i)

		const data = await api(
			`Items?$select=ItemName,ItemCode,U_Condition,U_Memory,U_Color,U_Item_Status&$filter=Frozen eq 'tNO' and U_Memory desc and contains(ItemName,'${name}') and (U_Item_Status eq 'Active' or U_Item_Status eq null)`,
			{
				headers: {
					Prefer: 'odata.maxpagesize=100',
				},
			},
		).then((res) => {
			return JSON.parse(res.data).value
		})

		setItemsLoading(false)
		setItemsData(data)
	}

	useEffect(() => {
		getBusinessPartners()
	}, [])

	const handleBusinessPartner = _.debounce((val) => {
		getBusinessPartners(val)
	}, 1000)

	useEffect(() => {
		setForm((prev) => ({ ...prev, DocumentLines: selectedItem }))
	}, [selectedItem])

	const handlePostBtnClick = () => {
		purchaseInvoicesPost()
	}

	const handleCurrencyChange = (event) => {
		setForm((prev) => ({ ...prev, DocCurrency: event.target.value }))
	}

	const handleBusinessPartnerClick = (event) => {
		if (inputRef.current) {
			inputRef.current.value = event.target.innerText
		}
		setForm((prev) => ({
			...prev,
			CardName: event.target.innerText,
			CardCode: businessPartnersData.find(
				(item) => item.CardName === event.target.value,
			).value,
		}))
		setBusinessPartnersData([])
	}

	const handleAddItem = () => {
		setItems((prev) => [...prev, { ...initItem }])
		setItemsName((prev) => [...prev, ''])
	}

	const handleRemoveItem = (i) => {
		setItems((prev) => prev.filter((_, idx) => idx !== i))
		setItemsData([])
		setItemsName((prev) => {
			const newItemsName = [...prev]
			newItemsName.splice(i, 1)
			return newItemsName
		})
	}

	const userFieldsFn = () => {
		customMainApi
			.get('UserFieldsMD', {
				params: {
					$filter: "TableName eq 'OITM'",
				},
			})
			.then((res) => {
				setUserFields(JSON.parse(res.data).value)
			})
	}

	useEffect(() => {
		userFieldsFn()
		getWarehouses()
	}, [])

	const getWarehouses = () => {
		setWarehouseLoading(true)
		customMainApi
			.get('Warehouses?$select=WarehouseCode,WarehouseName')
			.then((res) => {
				setWarehouses(JSON.parse(res.data).value)
			})
			.catch((err) => {
				if (err)
					errorRef.current?.open(
						JSON.stringify(err.response.data?.details?.message),
					)
			})
			.finally(() => {
				setWarehouseLoading(false)
			})
	}

	const handleClickItem = (code, i) => {
		const selectedItem = itemsData.find((v) => v.ItemCode === code)
		const color =
			get(selectedItem, 'U_Color', '') !== ''
				? userFields
						?.find((item) => item.Name === 'Color')
						?.ValidValuesMD.find(
							(value) => get(selectedItem, 'U_Color', '') === value.Value,
						)?.Description || ''
				: ''

		const memory =
			get(selectedItem, 'U_Memory', '') !== ''
				? userFields
						?.find((item) => item.Name === 'Memory')
						?.ValidValuesMD.find(
							(value) => get(selectedItem, 'U_Memory', '') === value.Value,
						)?.Description || ''
				: ''

		const condition = t(get(selectedItem, 'U_Condition', '')) ?? ''

		const itemDetails = `${selectedItem.ItemName} ${color} ${memory} ${condition}`

		setCondition(condition)
		setItems((prev) => [
			...prev.map((item, idx) => {
				if (idx === i) {
					return {
						...item,
						ItemCode: code,
						UnitPrice: items.find((v) => v.ItemCode === code)?.UnitPrice,
						batteryLife:
							condition === 'NEW' || condition === 'Новый' ? '100%' : '',
					}
				}
				return item
			}),
		])

		setItemsName((prev) => {
			const newArray = [...prev]
			newArray[i] = itemDetails
			return newArray
		})
		setItemsData([])
	}

	const handleSerialNumberChange = (event, i) => {
		setItems((prev) => [
			...prev.map((item, idx) => {
				if (idx === i) {
					return {
						...item,
						SerialNumbers: event.target.value,
					}
				}
				return item
			}),
		])
	}

	const handlePriceChange = (event, i) => {
		setItems((prev) => [
			...prev.map((item, idx) => {
				if (idx === i) {
					return {
						...item,
						UnitPrice: event.target.value,
					}
				}
				return item
			}),
		])
	}
	const handleRegionChange = (event, i) => {
		setItems((prev) => [
			...prev.map((item, idx) => {
				if (idx === i) {
					return {
						...item,
						U_Item_Region: event.target.value,
					}
				}
				return item
			}),
		])
	}

	const handleWarrantyChange = (event, i) => {
		setItems((prev) => [
			...prev.map((item, idx) => {
				if (idx === i) {
					return {
						...item,
						U_warranty: event.target.value,
					}
				}
				return item
			}),
		])
	}

	const handleBateryLifeChange = (val, i) => {
		setItems((prev) => [
			...prev.map((item, idx) => {
				if (idx === i) {
					return {
						...item,
						batteryLife: val,
					}
				}
				return item
			}),
		])
	}

	return (
		<Layout>
			<CreatePurchases>
				<div className="container">
					<div className="flex gap-4 items-end justify-between flex-col sm:flex-row">
						<div className="flex gap-2 items-start w-full">
							<div className="flex flex-col gap-2">
								<p className="text-gray-800">Поставщик</p>
								<AutoComplete
									options={businessPartnersData?.map((item) => ({
										value: `${item.value} ,/ ${item.label}`,
										label: (
											<div className="flex items-center justify-between">
												<span>{item.label}</span>
												<Tag color="blue">{item.value}</Tag>
											</div>
										),
										cardCode: item.value,
										cardName: item.label,
									}))}
									value={cardName}
									className="w-[300px]"
									onSelect={(value, label) => {
										console.log(value, label)
										setForm((prev) => ({
											...prev,
											CardName: label?.cardName,
											CardCode: label?.cardCode,
										}))
										setCardName(label?.cardName)
										setCardCode(label?.cardCode)
										handleBusinessPartner('')
									}}
									onChange={(val) => {
										handleBusinessPartner(val)
										setCardName(val)
									}}
									showSearch
								/>
							</div>
							<button
								className="bg-[#0A4D68] text-white px-4 rounded-md py-1 mt-8"
								onClick={() => createUserRef.current?.open()}
							>
								+
							</button>
						</div>
						<div className="flex flex-col gap-2 w-full ">
							<p className="text-gray-800">{t('date')}</p>
							<DatePicker
								value={date}
								format="DD.MM.YYYY"
								onChange={(date) => setDate(date)}
								className="border-[1px] border-[#DFE2E9] p-1 rounded-md w-full sm:w-[200px]"
								placeholder="Select a date"
								onInput={(e) => {
									if (e.target.value.length < 11) {
										setDate(e.target.value)
									}
								}}
							/>
						</div>
						<div className="flex flex-col gap-2 w-full">
							<p className="text-gray-800">{t('currency')}</p>
							<select
								className={
									'border-[1px] border-[#DFE2E9"] p-1 rounded-md w-full sm:w-[150px] flex-1'
								}
								onChange={handleCurrencyChange}
							>
								<option>USD</option>
								<option>UZS</option>
							</select>
						</div>
						{get(getMe, 'Department2.Name', '') === 'Boshqaruv' ? (
							<div className="w-full">
								<div className={'flex flex-col items-start gap-2 w-full '}>
									<p>{t('toWhs')}</p>
									<div className="flex items-center gap-2 w-full">
										<select
											id={'from-warehouse'}
											className={`bg-[#FAFBFC] border-[1px] ${
												!toWarehouse ? 'border-red-500' : 'border-[#DFE2E8]'
											} rounded-md p-1 pl-4 h-8 text-[#1E1F1F] w-full sm:w-[250px]`}
											onChange={(e) => setToWarehouse(e.target.value)}
											value={toWarehouse}
											required
										>
											<option value={null}>{t('selectWhs')}</option>
											{warehouses.map((v, i) => (
												<option key={i} value={v.WarehouseCode}>
													{v.WarehouseName}
												</option>
											))}
										</select>
										<ClipLoader size={15} loading={warehouseLoading} />
									</div>
								</div>
								{!toWarehouse && (
									<p className="text-red-500 text-sm w-full sm:w-[250px]">
										{t('whsRequired')}
									</p>
								)}
							</div>
						) : (
							''
						)}
						<div>
							<button
								className="bg-[#0A4D68] text-white px-4 rounded-md w-[180px] h-9 mt-8"
								onClick={() => {
									window.open('/addProduct', '_blank')
								}}
							>
								{t('addProduct')}
							</button>
						</div>
					</div>
					<div className="overflow-y-auto my-8">
						<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
							<thead className="text-xs text-gray-700 uppercase bg-gray-50 "></thead>
							<tbody>
								{items.map((v, i) => {
									return (
										<tr className="bg-white  hover:bg-gray-50 " key={i}>
											<td className="px-6 py-4 border">
												<div className="searchable-select">
													<div className={'searchableHead'}>
														<div className="relative">
															<p>{t('productName')}</p>
															<input
																className={
																	'border-[1px] border-[#DFE2E9"] p-1 mt-2 rounded-md w-[300px]'
																}
																type="text"
																onChange={(event) => {
																	debouncedSearchItem(event, i)
																	setItemsName((prev) => {
																		const newArray = [...prev]
																		newArray[i] = event.target.value
																		return newArray
																	})
																}}
																placeholder={t('productName')}
																value={itemsName[i]}
															/>
															{itemsLoading && (
																<div className="absolute top-9 right-5">
																	<ClipLoader size={20} color="#0A4D68" />
																</div>
															)}
															{!!itemsData.length && i === whichInput && (
																<div className="searchable-select-list">
																	{itemsData.map((v) => {
																		const itemName = get(v, 'ItemName', '')
																		const itemCode = get(v, 'ItemCode', '')

																		const color =
																			get(v, 'U_Color', '') !== ''
																				? userFields
																						?.find(
																							(item) => item.Name === 'Color',
																						)
																						?.ValidValuesMD.find(
																							(value) =>
																								get(v, 'U_Color', '') ===
																								value.Value,
																						)?.Description || ''
																				: ''

																		const memory =
																			get(v, 'U_Memory', '') !== ''
																				? userFields
																						?.find(
																							(item) => item.Name === 'Memory',
																						)
																						?.ValidValuesMD.find(
																							(value) =>
																								get(v, 'U_Memory', '') ===
																								value.Value,
																						)?.Description || ''
																				: ''

																		/* const condition =
																			get(v, 'U_Condition', '') !== ''
																				? userFields
																						?.find(
																							(item) =>
																								item.Name === 'Condition',
																						)
																						?.ValidValuesMD.find(
																							(value) =>
																								get(v, 'U_Condition', '') ===
																								value.Value,
																						)?.Description || ''
																				: '' */

																		const condition =
																			t(get(v, 'U_Condition', '')) ?? ''

																		const formattedValue = [
																			itemName,

																			color,
																			memory,
																			condition,
																			itemCode,
																		]
																			.filter(Boolean)
																			.join(' ')

																		return (
																			<div
																				onClick={() =>
																					handleClickItem(v.ItemCode, i)
																				}
																				key={v.ItemCode}
																				className="searchable-select-item "
																			>
																				{formattedValue}
																			</div>
																		)
																	})}
																</div>
															)}
														</div>
													</div>
												</div>
											</td>
											<td className="px-6 border py-4">
												<p>{t('region2')}</p>
												<input
													placeholder={t('region2')}
													className={
														'border-[1px] border-[#DFE2E9"] p-1 mt-2 rounded-md'
													}
													value={v.U_Item_Region}
													onChange={(event) => {
														if (event.target.value.length < 6) {
															handleRegionChange(event, i)
														}
													}}
												/>
											</td>
											<td className="px-6 border py-4">
												<p>{t('imei')}</p>
												<input
													placeholder={t('imei')}
													className={
														'border-[1px] border-[#DFE2E9"] p-1 mt-2 rounded-md'
													}
													value={v.SerialNumbers}
													onChange={(event) => {
														handleSerialNumberChange(event, i)
													}}
												/>
											</td>
											<td className="px-6 border py-4">
												<div>
													<p>{t('bateryLife')}</p>
													<input
														className={
															'border-[1px] border-[#DFE2E9"] p-1 mt-2 rounded-md w-[150px]'
														}
														type="text"
														onChange={(event) => {
															handleBateryLifeChange(event.target.value, i)
														}}
														placeholder={t('bateryLife')}
														value={v.batteryLife}
														disabled={
															condition === 'NEW' ||
															condition === 'Новый' ||
															condition === 'Yangi'
														}
													/>
												</div>
											</td>
											<td className="px-6 border py-4">
												<p>{t('price2')}</p>
												<input
													className={
														'border-[1px] border-[#DFE2E9"] p-1 mt-2 rounded-md'
													}
													// type="number"
													placeholder={t('price2')}
													onChange={(event) => {
														handlePriceChange(event, i)
													}}
													value={v.UnitPrice}
												/>
											</td>
											<td className="px-6 border py-4">
												<p>{t('garant')}</p>
												<select
													className={
														'border-[1px] border-[#DFE2E9"] w-[100px] p-1 mt-2 rounded-md'
													}
													onChange={(event) => {
														handleWarrantyChange(event, i)
													}}
													value={v.U_warranty}
												>
													{' '}
													<option key={0} value={''}>
														{''}
													</option>
													{[...Array(24)].map((_, index) => (
														<option key={index + 1} value={index + 1}>
															{index + 1}
														</option>
													))}
												</select>
											</td>
											{i === items.length - 1 ? (
												<button
													onClick={handleAddItem}
													className="p-4 bg-[#0A4D68] text-white rounded-md ml-2 mt-4"
												>
													+
												</button>
											) : null}
											{items.length > 1 ? (
												<button
													onClick={() => handleRemoveItem(i)}
													className="p-4 bg-red-600 text-white rounded-md ml-2 mt-4"
												>
													-
												</button>
											) : null}
										</tr>
									)
								})}
							</tbody>
						</table>
					</div>

					<div
						style={{
							display: 'flex',
							justifyContent: 'end',
							paddingRight: '50px',
							paddingTop: '30px',
						}}
					>
						<Button isLoading={btnLoading} onClick={handlePostBtnClick}>
							{t('create')}
						</Button>
					</div>
				</div>
			</CreatePurchases>
			<>
				<CreateSuppliersModal
					isLoading={createUserLoading}
					getRef={(r) => (createUserRef.current = r)}
					onClose={() => {
						getBusinessPartners()
					}}
				/>
				<SuccessModal
					getRef={(r) => {
						successModalRef.current = r
					}}
					onConfirm={() => navigate('/purchases')}
				/>
				<ErrorModal
					getRef={(r) => {
						errorRef.current = r
					}}
				/>
			</>
		</Layout>
	)
}

export default CreatePurchasesPage
