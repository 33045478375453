import React, { useState, useEffect } from 'react'
import moment from 'moment'
import api from '../../api'
import { IoCalculator } from 'react-icons/io5'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { calculator } from '../../store/slices'
import { Input, message } from 'antd'
import { useTranslation } from 'react-i18next'
import { formatNumber } from '../../utils/formatNumber'
import { FaCopy } from 'react-icons/fa'

export default function Calculator() {
	const dispatch = useDispatch()
	const { dollarRate, usdValue, total, isModalOpen } = useSelector(
		(state) => state.calculator,
		shallowEqual,
	)
	const { setUsdValue, setTotal, setIsModalOpen, setDollarRate } =
		calculator.actions

	const { t } = useTranslation()

	const [dollar, setDollar] = useState(dollarRate)
	const [isOpenModal, setIsOpenModal] = useState(isModalOpen)
	const [usd, setUsd] = useState(usdValue)
	const [uzsSum, setUzsSum] = useState(total)

	const getUSD = () => {
		api
			.post(`SBOBobService_GetCurrencyRate`, {
				Currency: 'UZS',
				Date: moment(),
			})
			.then((res) => {
				dispatch(setDollarRate(res.data))
				setDollar(JSON.parse(res.data))
			})
			.catch((err) => {
				setDollar('error')
			})
	}

	useEffect(() => {
		getUSD()
	}, [])

	useEffect(() => {
		setDollar(dollarRate)
		setIsOpenModal(isModalOpen)
		setUsd(usdValue)
		setUzsSum(total)
		console.log('UseEffect Worked')
	}, [dollarRate, isModalOpen, usdValue, total])

	return (
		<div
			className="absolute bottom-[20px]  right-[30px] z-[100]"
			onClick={(e) => e.stopPropagation()}
		>
			<button
				className="bg-sky-800 rounded-full p-3"
				onClick={() => {
					setIsOpenModal(!isOpenModal)
					dispatch(setIsModalOpen(!isOpenModal))
				}}
			>
				<IoCalculator className="text-white text-2xl" />
			</button>
			{isOpenModal && (
				<div
					className="absolute bottom-[70px] right-[5px] z-[100] bg-white drop-shadow-2xl shadow-2xl rounded-xl p-3"
					onClick={(e) => e.stopPropagation()}
				>
					<div className="flex flex-col gap-3">
						<div className="flex flex-col items-start gap-2">
							<p>{t('course')}</p>
							<Input
								value={dollar}
								onChange={(e) => {
									const value = e.target.value
									setDollar(value)
									dispatch(setDollarRate(value))
									dispatch(setTotal(value * uzsSum))
									setUzsSum(value * uzsSum)
								}}
								onClick={(e) => {
									e.stopPropagation()
								}}
								className="w-full"
							/>
						</div>
						<div className="flex flex-col items-start gap-2">
							<p>{t('usd')}</p>
							<Input
								value={usd}
								type="number"
								onWheel={(e) => {
									e.target.blur()
								}}
								onChange={(e) => {
									const value = e.target.value
									setUsd(value)
									dispatch(setUsdValue(value))
									dispatch(setTotal(dollar * value))
									setUzsSum(dollar * value)
								}}
								onClick={(e) => {
									e.stopPropagation()
								}}
								className="w-full"
							/>
						</div>
						<div className="flex flex-col items-start gap-2">
							<p>{t('total')}</p>
							<Input
								value={formatNumber(uzsSum)}
								onChange={(e) => {
									setUzsSum(e.target.value)
								}}
								disabled={true}
								onClick={(e) => {
									e.stopPropagation()
								}}
								className="w-full"
							/>
						</div>
						<div className="flex items-end gap-1">
							<div className="flex flex-col items-start gap-2">
								<p>{t('copyTotal')}</p>
								<Input
									value={`${dollar} * ${usd} = ${formatNumber(uzsSum)}`}
									onChange={(e) => {
										setUzsSum(e.target.value)
									}}
									disabled={true}
									onClick={(e) => {
										e.stopPropagation()
									}}
									className="w-[200px]"
								/>
							</div>
							<button
								onClick={() => {
									navigator.clipboard.writeText(
										`${dollar} * ${usd} = ${formatNumber(uzsSum)}`,
									)
									message.success(t('copiedToClipboard'))
								}}
								className="bg-blue-500 rounded-lg p-2 text-white hover:bg-blue-600"
							>
								<FaCopy />
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}
