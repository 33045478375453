import React, { useEffect, useRef, useState } from 'react'
import DashboardStyle from '../Dashboard'
import Button from '../../../components/Button'
import api, { customFuncsApi } from '../../../api'
import { get } from 'lodash'
import OutgoingPaymentsModal from '../../../components/Modal/OutgoingPymentsModal'
import ViewAccountModal from '../../../components/Modal/ViewAccountModal'
import ClipLoader from 'react-spinners/ClipLoader'
import moment from 'moment'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { DatePicker, Tag, Table, Empty } from 'antd'
import { useTranslation } from 'react-i18next'
import {
	ArrowDownOutlined,
	ArrowUpOutlined,
	EyeOutlined,
} from '@ant-design/icons'
const { RangePicker } = DatePicker

export default function Tab1() {
	const { getMe } = useSelector((state) => state.main)
	const dateFormat = 'YYYY-MM-DD'

	const OutgoingPaymentsModalRef = useRef()
	const ViewAccountModalRef = useRef()
	const { t } = useTranslation()

	const [isLoading, setIsLoading] = useState({
		loadingDebitCredit: false,
		loadingPurchases: false,
		loadingSold: false,
	})
	const [startDate, setStartDate] = useState(
		dayjs('2010-01-01').format('YYYY-MM-DD'),
	)
	const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'))
	const [salesData, setSalesData] = useState({
		data: [],
	})
	const [pageSold, setPageSold] = useState(0)
	const [pagePurchased, setPagePurchased] = useState(0)
	const [purchasedData, setPurchasedData] = useState({
		data: [],
		currentPage: 0,
	})
	const [dailyAccountStats, setDailyAccountStats] = useState([])
	const [balanceMoney, setBalanceMoney] = useState([])
	const [balanceLoading, setBalanceLoading] = useState(false)

	const [sortConfig, setSortConfig] = useState({
		column: null,
		direction: null,
		from: null,
	})

	const fetchDailyAccountStats = async () => {
		setIsLoading((prev) => ({ ...prev, loadingDebitCredit: true }))
		const accountsArray = [
			getMe?.U_CardUsdAccount !== null ? getMe?.U_CardUsdAccount : '',
			getMe?.U_CardAccount !== null ? getMe?.U_CardAccount : '',
			getMe?.U_CashAccount !== null ? getMe?.U_CashAccount : '',
			getMe?.U_CashUzsAccount !== null ? getMe?.U_CashUzsAccount : '',
			getMe?.U_TerminalAccount !== null ? getMe?.U_TerminalAccount : '',
			getMe?.U_TransAccount !== null ? getMe?.U_TransAccount : '',
		].filter(Boolean)

		const accounts = accountsArray.length ? JSON.stringify(accountsArray) : ''
		customFuncsApi
			.get(
				`xsSql/getDebitCreditSum?date1='${startDate + ' 00:00:00.000Z'}'&date2='${endDate + ' 00:00:00.000Z'}'${get(getMe, 'Department2.Name', '') !== 'Boshqaruv' ? `&accounts=${encodeURIComponent(accounts)}` : ''}`,
			)
			.then((res) => {
				setDailyAccountStats(res.data.value[0])
			})
			.finally(() => {
				setIsLoading((prev) => ({ ...prev, loadingDebitCredit: false }))
			})
	}

	const viewItem = (v) => {
		const url = `/viewItem?data=${encodeURIComponent(JSON.stringify(v))}&DocEntry=${v.DocEntry}`
		window.open(url, '_blank')
	}

	const sold = () => {
		setIsLoading((prev) => ({ ...prev, loadingSold: true }))
		customFuncsApi(
			`xsSql/getLastInitialPayments?date1='${startDate + ' 00:00:00.000Z'}'&date2='${endDate + ' 00:00:00.000Z'}'&%24skip=${pageSold}${get(getMe, 'Department2.Name', '') === 'Sotuv' ? `&WhsCode='${get(getMe, 'U_Warehouse', '')}'` : ''}${sortConfig.direction !== null ? `&orderBy=[{"name": "${sortConfig.column}", "type" : "${sortConfig.direction}"}]` : ''}`,
			{
				headers: {
					Prefer: 'odata.maxpagesize=10',
				},
			},
		)
			.then((res) => {
				const resData = res.data.value
				setSalesData({ data: [...resData] })
			})
			.catch((err) => {
				// errorRef.current?.open(
				//   get(JSON.parse(err.response.data), 'error.message', ''),
				// )
			})
			.finally(() => {
				setIsLoading((prev) => ({ ...prev, loadingSold: false }))
			})
	}

	const BalanceMoney = async () => {
		setBalanceLoading(true)
		let url =
			get(getMe, 'Department2.Name', '') === 'Sotuv'
				? `ChartOfAccounts?$filter=Code eq '${get(getMe, 'U_CardAccount', '')}' or Code eq '${get(getMe, 'U_CashAccount', '')}' or Code eq '${get(getMe, 'U_CashUzsAccount', '')}' or Code eq '${get(getMe, 'U_TerminalAccount', '')}' or Code eq '${get(getMe, 'U_TransAccount', '')}' and  ExternalCode eq '1'&$select=Code,Name,Balance,Balance_syscurr,Balance_FrgnCurr,AcctCurrency`
				: `ChartOfAccounts?$filter=startswith(Code, '50') and ExternalCode eq '1'&$select=Code,Name,Balance,Balance_syscurr,Balance_FrgnCurr,AcctCurrency`

		const data = await api
			.get(url, {
				headers: {
					Prefer: 'odata.maxpagesize=10000',
				},
			})
			.then((res) => JSON.parse(res.data).value)
		setBalanceMoney(data)
		setBalanceLoading(false)
	}

	const purchased = () => {
		setIsLoading((prev) => ({ ...prev, loadingPurchases: true }))
		customFuncsApi(
			`xsSql/getLastPurchases?date1='${startDate + ' 00:00:00.000Z'}'&date2='${endDate + ' 00:00:00.000Z'}'&$skip=${pagePurchased}${get(getMe, 'Department2.Name', '') === 'Sotuv' ? `&WhsCode='${get(getMe, 'U_Warehouse', '')}'` : ''}${sortConfig.direction !== null ? `&orderBy=[{"name": "${sortConfig.column}", "type" : "${sortConfig.direction}"}]` : ''}`,
			{
				headers: {
					Prefer: 'odata.maxpagesize=10',
				},
			},
		)
			.then((res) => {
				const resData = res.data.value
				setPurchasedData({ data: [...resData] })
			})
			.catch((err) => {
				// errorRef.current?.open(
				//   get(JSON.parse(err.response.data), 'error.message', ''),
				// )
			})
			.finally(() => {
				setIsLoading((prev) => ({ ...prev, loadingPurchases: false }))
			})
	}

	useEffect(() => {
		fetchDailyAccountStats()
		BalanceMoney()
	}, [startDate, endDate])

	useEffect(() => {
		if (sortConfig.from === 'purchased' || sortConfig.from === null) {
			purchased()
		}
	}, [startDate, endDate, pagePurchased, sortConfig])

	useEffect(() => {
		if (sortConfig.from === 'sold' || sortConfig.from === null) {
			sold()
		}
	}, [pageSold, startDate, endDate, sortConfig])

	const newDatas = (table) => {
		if (table === 'sold') {
			if (salesData.data.length < 10) {
				alert(t('noOtherInfo'))
			} else setPageSold((prev) => prev + 10)
		} else {
			if (purchasedData.data.length < 10) {
				alert(t('noOtherInfo'))
			} else setPagePurchased((prev) => prev + 10)
		}
	}

	const oldData = (table) => {
		if (table === 'sold' && pageSold <= 0) {
			alert(t('noOtherInfo'))
		} else if (table === 'purchased' && pagePurchased <= 0) {
			alert(t('noOtherInfo'))
		} else {
			table === 'sold'
				? setPageSold((prev) => prev - 10)
				: setPagePurchased((prev) => prev - 10)
		}
	}

	const handleStartDateChange = (date) => {
		setStartDate(date ? date.format('YYYY-MM-DD') : null)
	}

	const handleEndDateChange = (date) => {
		setEndDate(date ? date.format('YYYY-MM-DD') : null)
	}
	const onRangeChange = (dates, dateStrings) => {
		if (dates) {
			const start = dates[0]
			const end = dates[1]

			handleStartDateChange(start)
			handleEndDateChange(end)
		} else {
			console.error('Clear in Dashboard Tab1')
		}
	}
	const handleSort = (column, from) => {
		let direction = 'ASC'
		if (sortConfig.column === column && sortConfig.direction === 'ASC') {
			direction = 'DESC'
		} else if (
			sortConfig.column === column &&
			sortConfig.direction === 'DESC'
		) {
			direction = null
		}
		setSortConfig({ column, direction, from })
	}

	useEffect(() => {
		console.log('sortConfig', sortConfig)
	}, [sortConfig])

	return (
		<DashboardStyle>
			<div className="container">
				<p className="productTitle mb-10">{t('dashboard')}</p>
				<RangePicker
					value={[
						startDate ? dayjs(startDate, dateFormat) : null,
						endDate ? dayjs(endDate, dateFormat) : null,
					]}
					format={dateFormat}
					onChange={onRangeChange}
				/>
				<div className="flex flex-col pt-5 mv sm:flex-row mb-5 gap-2 items-start justify-between overflow-y-scroll">
					{isLoading.loadingDebitCredit || balanceLoading ? (
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							<ClipLoader
								loading={true}
								size={20}
								aria-label="Loading Spinner"
								className={'loader'}
								data-testid="loader"
							/>
						</div>
					) : (
						<>
							<div className="flex flex-col sm:flex-row gap-2 items-start ">
								<div className="rounded-xl drop-shadow-xl bg-white p-5	 min-w-[250px]">
									<Tag
										color="green"
										className="text-sm bg-white  p-[5px]  text-center rounded-lg w-fit"
									>
										{t('incomingPaymentToday')}
									</Tag>
									<p className="mt-4 text-xl font-bold text text-zinc-900 ">
										{new Intl.NumberFormat('fr-FR', {
											minimumFractionDigits: 2,
											maximumFractionDigits: 2,
										})
											.format(dailyAccountStats?.DebitSum)
											.replace(',', '.') || 0}{' '}
										USD
									</p>
								</div>
								<div
									className="rounded-xl drop-shadow-xl bg-white p-5	 min-w-[250px]"
									onClick={() => {
										OutgoingPaymentsModalRef.current?.open({
											startDate: startDate,
											endDate: endDate,
										})
									}}
								>
									<Tag
										color="red"
										className="text-sm bg-white  p-[5px]  text-center rounded-lg w-fit"
									>
										{t('outgoingPaymentToday')}
									</Tag>
									<p className="mt-4 text-xl font-bold text text-zinc-900">
										{new Intl.NumberFormat('fr-FR', {
											minimumFractionDigits: 2,
											maximumFractionDigits: 2,
										})
											.format(dailyAccountStats?.CreditSum)
											.replace(',', '.') || 0}{' '}
										USD
									</p>
								</div>
							</div>
							{get(getMe, 'Department2.Name', '') !== 'DokonManager' && (
								<div className="max-h-[30vh] overflow-y-scroll mt-10 sm:mt-0">
									<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
										<thead className="text-xs text-gray-700 uppercase bg-gray-50  ">
											<th scope="col" className="px-6 py-4">
												{t('schot')}
											</th>
											<th scope="col" className="px-10 py-4">
												{t('titleName')}
											</th>
											<th scope="col" className="px-6 py-4">
												{t('balance')}
											</th>
											<th scope="col" className="px-6 py-4">
												{t('currency')}
											</th>
											<th scope="col" className="px-6 py-4">
												{t('action')}
											</th>
										</thead>
										<tbody>
											{balanceMoney.length ? (
												balanceMoney.map((v, i) => {
													return (
														<tr
															className="bg-white border-b  hover:bg-gray-50 "
															key={i}
														>
															<td
																scope="row"
																className="px-6 py-4 font-medium text-gray-900 "
															>
																{v.Code}
															</td>
															<td
																scope="row"
																className="px-6 py-4 font-medium text-gray-900 "
															>
																{v.Name}
															</td>
															<td
																scope="row"
																className="px-6 py-4 font-medium text-gray-900 "
															>
																{v.AcctCurrency === 'UZS'
																	? new Intl.NumberFormat('fr-FR', {
																			minimumFractionDigits: 2,
																			maximumFractionDigits: 2,
																		})
																			.format(v.Balance_syscurr)
																			.replace(',', '.')
																	: new Intl.NumberFormat('fr-FR', {
																			minimumFractionDigits: 2,
																			maximumFractionDigits: 2,
																		})
																			.format(v.Balance)
																			.replace(',', '.')}
															</td>
															<td
																scope="row"
																className="px-6 py-4 font-medium text-gray-900 "
															>
																{v.AcctCurrency}
															</td>
															<td
																scope="row"
																className="px-6 py-4 font-medium text-gray-900 "
															>
																<Button
																	onClick={() => {
																		ViewAccountModalRef.current?.open({
																			startDate: dayjs().format('YYYY-MM-DD'),
																			endDate: dayjs().format('YYYY-MM-DD'),
																			accCode: v.Code,
																			accName: v.Name,
																		})
																	}}
																>
																	<EyeOutlined />
																</Button>
															</td>
														</tr>
													)
												})
											) : (
												<p className="px-6 py-4 font-medium text-gray-900 ">
													{t('noschot')}
												</p>
											)}
										</tbody>
									</table>
								</div>
							)}
						</>
					)}
				</div>

				<div className="mt-5">
					<h4 className={'font-bold text-lg  text-center mt-10 mb-4'}>
						{t('soldOut')}
					</h4>
					{isLoading.loadingSold ? (
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							<ClipLoader
								loading={true}
								size={20}
								aria-label="Loading Spinner"
								className={'loader'}
								data-testid="loader"
							/>
						</div>
					) : (
						<div className="overflow-y-auto mb-8">
							<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
								<thead className="text-xs text-gray-700 uppercase bg-gray-50  ">
									<tr>
										<th scope="col" className="px-6 py-4">
											{t('product')}
										</th>
										<th
											scope="col"
											className="px-6 py-4 cursor-pointer"
											onClick={() => handleSort('DocDate', 'sold')}
										>
											<div className="flex items-center justify-start gap-1">
												<span>{t('dateOfSale')}</span>
												<div>
													{sortConfig.from === 'sold' &&
													sortConfig.column === 'DocDate' ? (
														sortConfig.direction === 'ASC' ? (
															<ArrowUpOutlined className="arrowUp" />
														) : sortConfig.direction === 'DESC' ? (
															<ArrowDownOutlined className="arrowDown" />
														) : (
															<span className="flex gap-0.5 items-start justify-start">
																<ArrowUpOutlined />
																<ArrowDownOutlined />
															</span>
														)
													) : (
														<span className="flex gap-0.5 items-start justify-start">
															<ArrowUpOutlined />
															<ArrowDownOutlined />
														</span>
													)}
												</div>
											</div>
										</th>
										{/* <th scope="col" className="px-6 py-4">
											{t('dateOfSale')}
										</th> */}
										<th scope="col" className="px-6 py-4">
											{t('contribution')}
										</th>
										<th scope="col" className="px-6 py-4">
											{t('total')}
										</th>
										<th scope="col" className="px-6 py-4">
											{t('debtTotal')}
										</th>
									</tr>
								</thead>
								<tbody>
									{salesData.data.length > 0 ? (
										salesData.data.map((v, i) => {
											return (
												<tr
													key={i}
													onClick={() => viewItem(v)}
													className="bg-white border-b  hover:bg-gray-50 "
												>
													<td
														scope="row"
														className="px-6 py-4 font-medium text-gray-900 "
													>
														{v.Dscription}
													</td>
													<td className="px-6 py-4">
														{v.DocDate &&
															moment(v.DocDate).format('DD.MM.YYYY')}
													</td>
													<td className="px-6 py-4">
														{new Intl.NumberFormat('fr-FR', {
															minimumFractionDigits: 2,
															maximumFractionDigits: 2,
														})
															.format(v.SumAppliedAllCurrencies)
															.replace(',', '.') || 0}
													</td>
													<td className="px-6 py-4">
														{new Intl.NumberFormat('fr-FR', {
															minimumFractionDigits: 2,
															maximumFractionDigits: 2,
														})
															.format(v.DocTotal)
															.replace(',', '.') || 0}
													</td>
													<td className="px-6 py-4">
														{new Intl.NumberFormat('fr-FR', {
															minimumFractionDigits: 2,
															maximumFractionDigits: 2,
														})
															.format(v.DocTotal - v.SumAppliedAllCurrencies)
															.replace(',', '.') || 0}
													</td>
												</tr>
											)
										})
									) : (
										<tr>
											<td colSpan="10">
												<Empty
													description={t('noInfo')}
													image={Empty.PRESENTED_IMAGE_SIMPLE}
												/>
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
					)}

					<div className="topCard">
						<div className="flex">
							<Button
								disabled={pageSold === 0}
								className={'btn'}
								onClick={() => oldData('sold')}
								btnStyle={{ marginRight: 10 }}
							>
								{'<'}
							</Button>
							<Button
								disabled={salesData.data.length < 10}
								className={'btn'}
								onClick={() => newDatas('sold')}
							>
								{'>'}
							</Button>
						</div>
					</div>
				</div>

				<div>
					<h4 className={'font-bold text-lg text-center mt-10 mb-4'}>
						{t('buyed')}
					</h4>
					{isLoading.loadingPurchases ? (
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							<ClipLoader
								loading={true}
								size={20}
								aria-label="Loading Spinner"
								className={'loader'}
								data-testid="loader"
							/>
						</div>
					) : (
						<div className="overflow-y-auto mb-8">
							<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
								<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
									<tr>
										<th scope="col" className="px-6 py-4">
											{t('product')}
										</th>
										<th
											scope="col"
											className="px-6 py-4 cursor-pointer"
											onClick={() => handleSort('DocDate', 'purchased')}
										>
											<div className="flex items-center justify-start gap-1">
												<span>{t('dateOfSale')}</span>
												<div>
													{sortConfig.from === 'purchased' &&
													sortConfig.column === 'DocDate' ? (
														sortConfig.direction === 'ASC' ? (
															<ArrowUpOutlined className="arrowUp" />
														) : sortConfig.direction === 'DESC' ? (
															<ArrowDownOutlined className="arrowDown" />
														) : (
															<span className="flex gap-0.5 items-start justify-start">
																<ArrowUpOutlined />
																<ArrowDownOutlined />
															</span>
														)
													) : (
														<span className="flex gap-0.5 items-start justify-start">
															<ArrowUpOutlined />
															<ArrowDownOutlined />
														</span>
													)}
												</div>
											</div>
										</th>
										{/* <th scope="col" className="px-6 py-4">
											{t('dateOfSale')}
										</th> */}
										<th scope="col" className="px-6 py-4">
											{t('total')}
										</th>
										<th scope="col" className="px-6 py-4">
											{t('buyer')}
										</th>
										<th scope="col" className="px-6 py-4">
											{t('quantity')}
										</th>
									</tr>
								</thead>
								<tbody>
									{purchasedData.data.length > 0 ? (
										purchasedData.data.map((v, i) => {
											return (
												<tr
													key={i}
													className="bg-white border-b  hover:bg-gray-50 "
												>
													<td
														scope="row"
														className="px-6 py-4 font-medium text-gray-900 "
													>
														{v.Dscription}
													</td>
													<td className="px-6 py-4">
														{v.DocDate &&
															moment(v.DocDate).format('DD.MM.YYYY')}
													</td>
													<td className="px-6 py-4">
														{new Intl.NumberFormat('fr-FR', {
															minimumFractionDigits: 2,
															maximumFractionDigits: 2,
														})
															.format(v.Price * v.Quantity)
															.replace(',', '.') || 0}
													</td>
													<td className="px-6 py-4">{v.CardName}</td>
													<td className="px-6 py-4">
														{Math.round(v.Quantity)}
													</td>
												</tr>
											)
										})
									) : (
										<tr>
											<td colSpan="10">
												<Empty
													description={t('noInfo')}
													image={Empty.PRESENTED_IMAGE_SIMPLE}
												/>
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
					)}

					<div className="topCard">
						<div className="flex">
							<Button
								disabled={pagePurchased === 0}
								className={'btn'}
								onClick={() => oldData('purchased')}
								btnStyle={{ marginRight: 10 }}
							>
								{'<'}
							</Button>
							<Button
								disabled={purchasedData.data.length < 10}
								className={'btn'}
								onClick={() => newDatas('purchased')}
							>
								{'>'}
							</Button>
						</div>
					</div>
				</div>

				<OutgoingPaymentsModal
					getRef={(r) => {
						OutgoingPaymentsModalRef.current = r
					}}
				/>
				<ViewAccountModal
					getRef={(r) => {
						ViewAccountModalRef.current = r
					}}
				/>
			</div>
		</DashboardStyle>
	)
}
