import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
	SuccessModal,
	ErrorModal,
	PaymentModal,
	AcceptCancelModal,
} from '../../components/Modal'
import AddPoliceOutgoingModal from '../../components/Modal/AddPoliceOutgoingModal'
import { useLocation, useNavigate } from 'react-router-dom'
import NoDollar from '../CreateProducts/NoDollar'
import Layout from '../../components/Layout'
import Button from '../../components/Button'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import api, { customFuncsApi, customPhotoUploadApi } from '../../api'
import moment from 'moment'
import html2PDF from 'jspdf-html2canvas'
import customMainApi from '../../api/index'
import { Empty, message, Input, Button as AntdBtn, Tag } from 'antd'
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'
import UpdateUser from './UpdateUser'
import { IoIosPrint, IoMdDownload } from 'react-icons/io'
import formatPhoneNumber from '../../helpers/PhoneWithSpaces'
import { useTranslation } from 'react-i18next'
import { AiOutlineSend } from 'react-icons/ai'
import { isEmpty } from 'lodash/lang'
import CloseIcon from '../../assets/icons/close'
import qr from '../../assets/images/QRCode.png'
import logo from '../../assets/images/logo.png'
import './main.css'
import EditIcon from '../../assets/icons/edit'

const ViewItem = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const { t } = useTranslation()

	const successModalRef = useRef()
	const errorRef = useRef()
	const successRef = useRef()
	const noDollarRef = useRef()
	const paymentRef = useRef()
	const acceptRef = useRef()
	const updateUserRef = useRef()
	const policeExpenseRef = useRef()

	const { getMe } = useSelector((state) => state.main)
	const query = new URLSearchParams(location.search)
	const data = JSON.parse(query.get('data')) || {}
	const DocEntry = parseInt(query.get('DocEntry'), 10) || 0
	const notShow = query.get('notShow') || false
	const cardCode = query.get('CardCode') || ''

	const getNoDollarRef = useCallback((ref) => {
		noDollarRef.current = ref
	}, [])

	const [tableData, setTableData] = useState([])
	const [tableData2, setTableData2] = useState([])
	const [deviceInfoData, setDeviceInfoData] = useState([])
	const [downloadData, setDownloadData] = useState({ state: location.state })
	const [info1, setInfo1] = useState('')
	const [tableInvoicePayments, setTableInvoicePayments] = useState([])
	const [info2, setInfo2] = useState('')
	const [paymentSum, setPaymentSum] = useState(0)
	const [U_DaftarBet, setU_DaftarBet] = useState('')
	const [phoneNum, setPhoneNum] = useState('')
	const [oitm, setOitm] = useState([])
	const [supplier, setSupplier] = useState('')
	const [purchaseDate, setPurchaseDate] = useState('')
	const [sumPay, setSumPay] = useState(0)
	const [dollar, setDollar] = useState(1)
	const [dollarLoading, setDollarLoading] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [isLoading2, setIsLoading2] = useState(false)
	const [isLoading3, setIsLoading3] = useState(false)
	const [isLoading4, setIsLoading4] = useState(false)
	const [isLoading5, setIsLoading5] = useState(false)
	const [isCommitLoading, setIsCommitLoading] = useState(false)
	const [userFields, setUserFields] = useState([])
	const [boxPlace, setBoxPlace] = useState('')
	const [comment, setComment] = useState('')
	const [comments, setComments] = useState([])
	const [comment2, setComment2] = useState('')
	const [debtTotal, setDebtTotal] = useState('')
	const [editComment, setEditComment] = useState(false)
	const [editDaftarBet, setEditDaftarBet] = useState(false)
	const [typeAccount, setTypeAccount] = useState('')
	const [iclodName, setIcloudName] = useState('')
	const [icloudStatus, setIcloudStatus] = useState('')
	const [invoiceDocNum, setInvoiceDocNum] = useState('')
	const [firstPayingMoney, setFirstPayingMoney] = useState(0)
	const [invoiceDate, setInvoiceDate] = useState('')
	const [memory, setMemory] = useState('')
	const [docTotal, setDocTotal] = useState('')
	const [paidToDate, setPaidToDate] = useState('')
	const [bateryLife, setBateryLife] = useState('')
	const [percentInPurchase, setPercentInPurchase] = useState('')
	const [regionInPurchase, setRegionInPurchase] = useState('')
	const [path, setPath] = useState([])
	const [costPrice, setCostPrice] = useState('')
	const [whsCode, setWhsCode] = useState('')
	const [whsData, setWhsData] = useState([])
	const [itemName, setItemName] = useState('')

	useEffect(() => {
		getInformationOfItem()
		getInfoOFDevice()
		getInvoicePayments()
		userFieldsFn()
		getBp()
		userFieldsOITM()
		getComments()
		getLastPurchases()
		getUSD()
		getIcloudName()
		getWarehouses()
	}, [])

	const handleFileChange = (event, index) => {
		const updatedPath = [...path]
		updatedPath[index].file = event.target.files[0]
		setPath(updatedPath)
	}

	const uploadImg = async (index) => {
		const image = path[index]
		setIsLoading5(true)

		if (!image.file) {
			message.error('Фотография не выбрано')
			return
		}

		const formData = new FormData()
		formData.append('file', image.file)

		const generateRandomKey = () => {
			const characters =
				'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
			let randomKey = ''
			for (let i = 0; i < 16; i++) {
				randomKey += characters.charAt(
					Math.floor(Math.random() * characters.length),
				)
			}
			return randomKey
		}

		const randomKey = generateRandomKey()
		const title = 'YourTitle' // Replace with actual title

		formData.append('Title', title)
		formData.append('Key', randomKey)

		try {
			const updatedPath = [...path]
			updatedPath[index].imgLoading = true
			setPath(updatedPath)

			const res = await customPhotoUploadApi.post('assets/upload', formData, {
				headers: { 'Content-Type': 'multipart/form-data' },
			})

			const { key } = res.data
			updatedPath[index].path = key
			setPath(updatedPath)

			message.success('Успешно загружено!')
		} catch (err) {
			message.error('Ошибка загрузки')
		} finally {
			const updatedPath = [...path]
			updatedPath[index].imgLoading = false
			setPath(updatedPath)
			setIsLoading5(false)
		}
	}

	const viewImage = async (index) => {
		const image = path[index]
		setIsLoading4(true)

		try {
			const res = await customPhotoUploadApi.get(
				`assets/download/${image.path}`,
				{
					responseType: 'blob',
				},
			)

			const url = window.URL.createObjectURL(
				new Blob([res.data], { type: 'image/png' }),
			)

			const link = document.createElement('a')
			link.href = url
			link.setAttribute('download', 'BP.png')
			document.body.appendChild(link)
			link.click()

			document.body.removeChild(link)
			window.URL.revokeObjectURL(url)
		} catch (error) {
			message.error('Ошибка загрузки файла')
		} finally {
			setIsLoading4(false)
		}
	}

	const addNewRow = () => {
		setPath([...path, { file: null, path: '', imgLoading: false }])
	}

	const deleteImage = async (index) => {
		const image = path[index]

		try {
			const res = await customPhotoUploadApi.delete(`assets/${image.path}`)
			const photoId = path
				.map((images) => images.path)
				.filter((p) => p !== image.path) // Correct filter syntax
				.join(',')
			await api.patch(`Invoices(${get(data, 'DocEntry', 0)})`, {
				U_PhotoID: photoId,
			})
			deleteRow(index) // Remove the image from the state after deletion

			message.success('Успешно удалено!')
		} catch (error) {
			message.error('Ошибка удаления файла')
		}
	}

	const deleteRow = (index) => {
		setPath(path.filter((_, i) => i !== index))
	}

	const getInformationOfItem = () => {
		setIsLoading(true)
		customFuncsApi
			.get(`XsSql/getInstallments?docEntry=${DocEntry}`, {
				headers: {
					Prefer: 'odata.maxpagesize=50',
				},
			})
			.then((res) => {
				const resData = res.data.value || []
				setTableData(resData)
				setIsLoading(false)
				let a = JSON.parse(JSON.stringify(resData))
				let allSum = 0
				let paySum = 0
				const now = moment().format('YYYYMMDD')
				for (let i = 0; i < a.length; i++) {
					if (!moment(a[i].DueDate, 'YYYYMMDD').isAfter(now, 'day')) {
						allSum = allSum + a[i].PaidToDate
						paySum = paySum + a[i].InsTotal
					}
				}
				setSumPay(allSum)
				setPaymentSum(paySum)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}

	const userFieldsFn = () => {
		customMainApi
			.get('UserFieldsMD', {
				params: {
					$filter: "TableName eq 'OINV'",
				},
				headers: {
					Prefer: 'odata.maxpagesize=100',
				},
			})
			.then((res) => {
				setUserFields(JSON.parse(res.data).value)
			})
	}

	const userFieldsOITM = () => {
		customMainApi
			.get('UserFieldsMD', {
				params: {
					$filter: "TableName eq 'OITM' ",
				},
			})
			.then((res) => {
				setOitm(JSON.parse(res.data).value)
			})
	}

	const getUSD = () => {
		setDollarLoading(true)
		api
			.post(`SBOBobService_GetCurrencyRate`, {
				Currency: 'UZS',
				Date: moment(),
			})
			.then((res) => {
				setDollar(JSON.parse(res.data))
			})
			.catch((err) => {
				setDollar('error')
			})
			.finally(() => setDollarLoading(false))
	}

	const allSaves = () => {
		setIsCommitLoading(true)
		const photoId = path
			.map((image) => image.path)
			.filter(Boolean)
			.join(',')
		api
			.patch(`Invoices(${get(data, 'DocEntry', 0)})`, {
				U_BoxPlace: boxPlace,
				U_TypeAccount: typeAccount,
				U_PhotoID: photoId,
				U_COMMENT: comment2,
				U_DaftarBet: U_DaftarBet,
			})
			.then(() => {
				successRef.current?.open('Заказ успешно сохранен')
				setIsCommitLoading(false)
			})
			.catch((err) => {
				setIsCommitLoading(false)
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}

	const getBp = () => {
		api
			.get(
				`BusinessPartners('${get(data, 'CardCode', 0)}')?$select=U_Telephone,CardName,FreeText,U_Comment`,
			)
			.then((res) => {
				setPhoneNum(get(JSON.parse(res.data), 'U_Telephone', ''))
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}

	const getIcloudName = () => {
		// ?$select=$select=Code,Name,U_Status,U_TypeAccount
		if (
			get(data, 'U_Security', '') !== null &&
			get(data, 'U_Security', '') !== undefined &&
			get(data, 'U_Security', '') !== ''
		) {
			api
				.get(`Security('${get(data, 'U_Security', '')}')`)
				.then((res) => {
					setIcloudName(get(JSON.parse(res.data), 'Name', ''))
					setIcloudStatus(get(JSON.parse(res.data), 'U_Status', ''))
				})
				.catch((err) => {
					errorRef.current?.open(
						get(JSON.parse(err.response.data), 'error.message', ''),
					)
				})
		}
	}

	const getWarehouses = () => {
		customMainApi
			.get('Warehouses?$select=WarehouseCode,WarehouseName')
			.then((res) => {
				setWhsData(JSON.parse(res.data).value)
			})
			.catch((err) => {
				if (err)
					errorRef.current?.open(
						JSON.stringify(err.response.data?.details?.message),
					)
			})
	}

	const getInfoOFDevice = () => {
		setIsLoading2(true)

		api
			.get(`Invoices(${DocEntry})`)
			.then((res) => {
				try {
					const parsedData = JSON.parse(res.data)

					const resData = get(parsedData, 'DocumentLines[0]', [])
					const uDaftarPage = get(parsedData, 'U_DaftarBet', null)
					const boxPlace = get(parsedData, 'U_BoxPlace', null)
					const typeAccount = get(parsedData, 'U_TypeAccount', null)
					const photoId = get(parsedData, 'U_PhotoID', '')
					const comment2 = get(parsedData, 'U_COMMENT', '')
					const docNum = get(parsedData, 'DocNum', '')
					const firstPayment = get(parsedData, 'U_FirstPayment', '')
					const docTotal = get(parsedData, 'DocTotal', '')
					const paidToDate = get(parsedData, 'PaidToDate', '')
					const docDate = get(parsedData, 'DocDate', '')
					const costPrice = get(parsedData, 'U_CostPrice', '')

					setU_DaftarBet(uDaftarPage)
					setBoxPlace(boxPlace)
					setTypeAccount(typeAccount)
					getItems(get(resData, 'ItemCode', ''))
					setBateryLife(get(resData, 'U_BatteryLifeInvoice', ''))
					setDeviceInfoData(resData)
					setDownloadData(parsedData)
					setComment2(comment2)
					setInvoiceDocNum(docNum)
					console.log('debtTotal =', docTotal - paidToDate)
					setDebtTotal(docTotal - paidToDate)
					setPaidToDate(paidToDate)
					setDocTotal(docTotal)
					setCostPrice(costPrice)
					setInvoiceDate(moment(docDate).format('DD.MM.YYYY'))
					setWhsCode(get(resData, 'WarehouseCode', ''))
					setFirstPayingMoney(firstPayment)
					const photoPaths = photoId
						? photoId.includes(',')
							? photoId.split(',')
							: [photoId]
						: []

					const initialPath = photoPaths.map((path) => ({
						file: 'not empty',
						has: true,
						path,
						imgLoading: false,
					}))

					const finalPath =
						initialPath.length > 0
							? initialPath
							: [{ file: null, path: '', imgLoading: false }]

					setPath(finalPath)
				} catch (error) {
					console.error('Error parsing response data in ViewItem:', error)
				} finally {
					setIsLoading2(false)
				}
			})
			.catch((err) => {
				setIsLoading2(false)
				// Ensure that the error response is handled safely
				const errorMessage = get(
					JSON.parse(err.response?.data || '{}'),
					'error.message',
					'Unknown error',
				)
				errorRef.current?.open(errorMessage)
			})
	}

	const getLastPurchases = () => {
		setIsLoading2(true)

		customFuncsApi
			.get(`xsSql/getLastPurchaseOfItemByInvoiceId?DocEntry=${DocEntry}`)
			.then((res) => {
				try {
					setSupplier(get(res.data, 'CardName', ''))
					setPurchaseDate(
						get(res.data, 'DocDate', '') !== null
							? moment(get(res.data, 'DocDate', '')).format('DD.MM.YYYY')
							: '',
					)
					setRegionInPurchase(get(res.data, 'U_Region_Item'), '' || '')
					setPercentInPurchase(get(res.data, 'U_Percent', '') || '')
				} catch (error) {
					console.error('Error parsing response data in View Item:', error)
				} finally {
					setIsLoading2(false)
				}
			})
			.catch((err) => {
				setIsLoading2(false)
				const errorMessage = get(
					JSON.parse(err.response?.data || '{}'),
					'error.message',
					'Unknown error',
				)
				errorRef.current?.open(errorMessage)
			})
	}

	const getItems = (code) => {
		api
			.get(`Items('${code}')`)
			.then((res) => {
				const resData = JSON.parse(res.data)
				console.log('resData = ', resData)
				const itemName = get(resData, 'ItemName', '') || ''
				const memory = get(resData, 'U_Memory', '') || ''
				const color = get(resData, 'U_Color', '') || ''
				const condition = get(resData, 'U_Condition', '') || ''

				setItemName(`${itemName} ${color} ${memory} ${condition}`)
				setInfo1(
					get(JSON.parse(res.data), 'U_Condition', 'Состояние не найден'),
				)
				const dataO = JSON.parse(res.data)
				setMemory(get(dataO, 'U_Memory', ''))

				setInfo2(get(dataO, 'U_Color', ''))
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}

	const createPayment = ({
		DocDate,
		CurrencyRate,
		AmountUsd,
		AmountUzs,
		AmountPayme,
		NumberCard,
		AmountSkidka,
		SkidkaCard,
		Terminal,
		smsSend,
		AmountUsdCard,
		SecurityName,
		Time,
		isSkidka,
		isSpisaniya,
	}) => {
		setIsLoading(true)
		const payments = []
		if (AmountUsd) {
			payments.push({
				usdSum: AmountUsd,
				account: getMe.U_CashAccount,
				currency: 'USD',
				uzsSum: null,
				additionalInfo: {
					U_CashFlow: '1',
				},
			})
		}
		if (AmountUzs) {
			payments.push({
				usdSum: AmountUzs / CurrencyRate,
				account: getMe.U_CashUzsAccount,
				currency: 'UZS',
				uzsSum: AmountUzs,
				additionalInfo: {
					U_CashFlow: '1',
				},
			})
		}
		if (AmountPayme) {
			payments.push({
				usdSum: AmountPayme / CurrencyRate,
				account: NumberCard || getMe.U_CardAccount,
				currency: 'UZS',
				uzsSum: AmountPayme,
				additionalInfo: {
					U_CashFlow: '1',
				},
			})
		}
		if (AmountSkidka) {
			payments.push({
				usdSum: AmountSkidka,
				account: SkidkaCard,
				currency: 'USD',
				uzsSum: null,
				additionalInfo: {
					U_CashFlow: '1',
					...(isSkidka === 'Yes' ? { U_IsDebtWaiver: 'Y' } : {}),
					...(isSpisaniya === 'Yes' ? { U_IsSpisanie: 'Y' } : {}),
				},
			})
		}
		if (Terminal) {
			payments.push({
				usdSum: Terminal / CurrencyRate,
				account: getMe.U_TerminalAccount,
				currency: 'UZS',
				uzsSum: Terminal,
				additionalInfo: {
					U_CashFlow: '1',
				},
			})
		}
		if (AmountUsdCard) {
			payments.push({
				usdSum: AmountUsdCard,
				account: getMe.U_CardUsdAccount,
				currency: 'USD',
				uzsSum: null,
				additionalInfo: {
					U_CashFlow: '1',
				},
			})
		}
		customFuncsApi
			.post('addIncomingPayments', {
				DocDate,
				DocEntry: DocEntry,
				U_Time: Time,
				CardCode: get(data, 'CardCode', null),
				DocRate: CurrencyRate,
				shouldSendSms: smsSend,
				payments: payments,
				'Security.U_Status': SecurityName,
			})
			.then((err) => {
				paymentRef.current?.close()
				successModalRef.current?.open('Платеж успешно произведен!')
				getInformationOfItem()
				getInfoOFDevice()
				getInvoicePayments()
			})
			.catch((err) => {
				paymentRef.current?.close()
				errorRef.current?.open(JSON.stringify(err.response.data))
			})
			.finally(() => {
				setIsLoading(false)
			})
	}

	const getInvoicePayments = () => {
		setIsLoading(true)
		customFuncsApi
			.get(`XsSql/getInvoiceIncomingPayments?docEntry=${DocEntry}`, {
				headers: {
					Prefer: 'odata.maxpagesize=100',
				},
			})
			.then((res) => {
				setTableInvoicePayments(res.data.value)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}

	const cancel = () => {
		if (notShow) {
			navigate(`/users-invoices?CardCode=${cardCode}`)
		} else if (data.Installmnt < 2) {
			navigate('/cash-payment')
		} else if (data.Installmnt > 1) {
			navigate('/installment-plan')
		}
	}

	const sendComment = () => {
		setComment('')
		const date = moment().tz('Etc/GMT-5').format('YYYY-MM-DDTHH:mm:ssZ')

		const body = {
			U_Dunner: get(getMe, 'EmployeeID', ''),
			U_CreateDate: date,
			U_Invoice: get(data, 'DocEntry', ''),
			U_InstlmntID: 1,
			U_Text: comment,
			U_CreateTime: date,
			Name: `${get(getMe, 'FirstName', '')} ${get(getMe, 'LastName', '')}`,
		}
		api
			.post('U_COMMENTS', body)
			.then((res) => getComments())
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}

	const getComments = () => {
		api
			.get(
				`U_COMMENTS?$filter=U_Invoice eq ${DocEntry} &$orderby=U_CreateTime asc, Code desc`,
			)
			.then((res) => {
				setComments(get(JSON.parse(res.data), 'value', []))
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
			.finally(() => {
				// setCommentsLoading(false)
			})
	}

	const deleteComments = (id, order) => {
		api
			.delete(`U_COMMENTS(${id})`)
			.then((res) => getComments())
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
		// .finally((res) => {
		// 	setIsComment((prevState) => ({
		// 		...prevState,
		// 		loading: false,
		// 		order: -1,
		// 	}))
		// })
	}

	const formatPhoneNumber2 = (phoneNum) => {
		if (!phoneNum || typeof phoneNum !== 'string') {
			return ''
		}
		const parts = phoneNum.split(/[,]/).filter(Boolean)

		return parts.slice(0, 2).join(', ')
	}

	const handleClose = (docEntry) => {
		setIsLoading3(true)
		customFuncsApi
			.post(`cancelInvoice/${docEntry}`, {
				'Security.U_Status': 'bosh',
			})
			.then((err) => {
				acceptRef.current?.close()
				successModalRef.current?.open('Продажа успешно отменено!')
				setTimeout(() => {
					navigate(-1)
				}, 2000)
			})
			.catch((err) => {
				// errorRef.current?.open(JSON.stringify(err.response.data))
				acceptRef.current?.close()
				errorRef.current?.open(get(err.response.data, 'message', ''))
			})
			.finally(() => {
				setIsLoading3(false)
			})
	}

	const downloadCheck = (id, data) => {
		let element = document.getElementById('check' + id)
		element.style.display = 'block'

		element.style.width = '210mm'
		element.style.height = '297mm'

		html2PDF(element, {
			margin: 0,
			filename: 'myfile.pdf',
			image: { type: 'jpeg', quality: 0.98 },
			html2canvas: {
				scale: 2,
				logging: true,
				dpi: 150,
				letterRendering: true,
			},
			jsPDF: {
				unit: 'mm',
				format: 'a4',
				orientation: 'portrait',
			},
		}).then(() => {
			element.style.display = 'none'
		})
	}

	const navigateToPrint = (id, v, data) => {
		const totalInUZS = (
			Number(v.CashSum).toFixed(2) * Number(v.DocRate)
		).toFixed(2)
		const formattedData = {
			InstId: v.visualInstNum,
			DocDate: v.DocDate,
			totalInUZS: totalInUZS,
			CardName: get(data, 'CardName', '-'),
			phoneNum: phoneNum,
			Dscription: get(data, 'Dscription', '-'),
			Time: v.U_Time,
			IncomingDocNum: get(v, 'DocNum', ''),
			CardCode: get(data, 'CardCode', ''),
			InvoiceDocNum: invoiceDocNum,
			debtTotal: debtTotal,
		}
		navigate('/cheque', { state: { id, data: formattedData } })
	}

	const viewItem = (v) => {
		updateUserRef.current?.open(v)
	}

	return (
		<Layout>
			<div className="flex gap-5 flex-col md:flex-row flex-1">
				<div className=" shadow-lg  w-full p-5  border-[1px] border-[#DFE2E8] overflow-y-scroll flex flex-col 3xl:flex-row gap-5 w-full">
					<div className="grid grid-cols-3 w-full gap-6">
						<div className="grid col-span-3 sm:grid-cols-2 gap-6 p-1 sm:p-5 mb-8 h-fit w-full">
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									{t('codeCredit')}
								</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={`${get(data, 'DocNum', '')}`}
									disabled={true}
								/>
							</div>
							<div className="flex items-center w-full">
								<div className="w-full">
									<p className={'font-medium text-zinc-600 text-sm mb-1'}>
										{t('FIO')}
									</p>
									<input
										type="text"
										className={
											'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
										}
										value={`${get(data, 'CardName', 'Покупатель')}`}
										disabled={true}
									/>
								</div>
								<div className="w-[50px] mt-6">
									<Button
										onClick={() => {
											viewItem(get(data, 'CardCode', ''))
										}}
										className="w-[50px] h-10"
									>
										<EyeOutlined className="w-[50px]" />
									</Button>
								</div>
							</div>
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									{t('itemDescription')}
								</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									// value={`${get(data, 'Dscription', '')}  ${get(data, 'ItemDescription', '')} `}
									value={`${itemName} ${regionInPurchase}`}
									disabled={true}
								/>
							</div>
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>IMEI</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={`${get(
										deviceInfoData,
										'SerialNumbers[0].InternalSerialNumber',
										'-',
									)}`}
									disabled={true}
								/>
							</div>
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									{t('dateAndPriceOfSale')}
								</p>
								<input
									type="text"
									placeholder={t('dateOfSale')}
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={`${invoiceDate} / ${get(deviceInfoData, 'GrossTotal', 'Цена не найден')}`}
									disabled={true}
								/>
							</div>
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									{t('supplierAndCostPrice')}
								</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={`${supplier} /  ${purchaseDate} / ${get(
										deviceInfoData,
										'GrossBuyPrice',
										'Текущая цена не найден',
									)} `}
									disabled={true}
								/>
							</div>
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									{t('bateryLifeInPurchase')}
								</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={percentInPurchase && `${percentInPurchase}`}
									disabled={true}
								/>
							</div>
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									{t('bateryLifeInSale')}
								</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={bateryLife && `${bateryLife}`}
									disabled={true}
								/>
							</div>

							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									{t('cashPrice')}
								</p>
								<input
									type="number"
									placeholder={t('cashPrice')}
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={costPrice || 'Цена не найден'}
									disabled={true}
								/>
							</div>
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									{t('address2')}
								</p>
								<textarea
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									rows="1"
									disabled={true}
									value={`${get(data, 'Comments', '')}`}
								/>
							</div>
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									{t('garant')}
								</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={
										(get(data, 'U_Guarantor', '-') &&
											`${get(data, 'U_Guarantor', '-')}`) ||
										'-'
									}
									disabled={true}
								/>
							</div>
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									{t('comment')}
								</p>
								<div className="flex items-center">
									{' '}
									<textarea
										name="comment"
										// id="comment"
										className={
											'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
										}
										// cols="30"
										rows="1"
										value={comment}
										onChange={(v) => setComment(v.target.value)}
									/>
									<button
										className={'sendComment'}
										onClick={() => sendComment()}
										disabled={isEmpty(comment)}
									>
										<AiOutlineSend />
									</button>
								</div>
							</div>
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									{t('whsName')}
								</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={
										whsData.find((item) => item?.WarehouseCode === whsCode)
											?.WarehouseName || ''
									}
									disabled={true}
								/>
							</div>
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									{t('box')}
								</p>
								<select
									className={
										'border-[1px] border-[#DFE2E9"] p-1 mt-2 rounded-md w-full'
									}
									onChange={(event) => setBoxPlace(event.target.value)}
									value={boxPlace}
								>
									<option value=""> </option>
									{userFields
										?.find((item) => item.Name === 'BoxPlace')
										?.ValidValuesMD.filter((value) => {
											if (data.Installmnt < 2) {
												return (
													value.Description === 'Bor' ||
													value.Description === 'Yoq'
												)
											} else {
												return (
													value.Description !== 'Bor' &&
													value.Description !== 'Yoq'
												)
											}
										})
										.map((value) => (
											<option key={value.Value} value={value.Value}>
												{value.Description}
											</option>
										))}
								</select>
							</div>
							{data.Installmnt > 1 ? (
								<>
									{' '}
									<div>
										<p className={'font-medium text-zinc-600 text-sm mb-1'}>
											{t('icloud')}
										</p>
										<input
											// placeholder="Номер страницы"
											className={
												'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
											}
											value={`${iclodName}`}
											disabled={true}
										/>
									</div>
									<div>
										<p className={'font-medium text-zinc-600 text-sm mb-1'}>
											{t('firstPayingMonth')}
										</p>
										<input
											className={
												'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
											}
											value={firstPayingMoney}
											disabled={true}
										/>
									</div>
									<div></div>
								</>
							) : (
								<div></div>
							)}
							<div></div>
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									{t('pageNumber')}
								</p>
								<input
									// placeholder="Номер страницы"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={U_DaftarBet || ''}
									onChange={(e) => {
										setU_DaftarBet(e.target.value)
									}}
									disabled={!editDaftarBet}
								/>
							</div>

							<div className="w-[20px] mt-6">
								<AntdBtn
									type="primary"
									onClick={() => {
										setEditDaftarBet(!editDaftarBet)
									}}
									className="bg-[#0A4D68] text-white  h-10"
								>
									<EditIcon className="w-[20px] text-xl" />
								</AntdBtn>
							</div>
							<div className="w-full">
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									{t('commentInventory')}
								</p>
								<div className="flex items-center gap-2 w-full">
									<textarea
										className={
											'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
										}
										value={comment2}
										disabled={!editComment}
										onChange={(e) => {
											setComment2(e.target.value)
										}}
									/>
								</div>
							</div>
							<div className="w-[20px] mt-10">
								<AntdBtn
									type="primary"
									onClick={() => {
										setEditComment(!editComment)
									}}
									className="bg-[#0A4D68] text-white  h-10"
								>
									<EditIcon className="w-[20px] text-xl" />
								</AntdBtn>
							</div>

							<div className="w-full sm:w-[600px]">
								{path.map((image, index) =>
									image.has ? (
										image.path && (
											<div
												key={index}
												className="flex flex-col w-full items-start gap-1  mb-5"
											>
												<div>
													<p className="font-bold">
														{t('photo')}-{index + 1}
													</p>
												</div>
												<div className="flex items-center flex-col sm:flex-row gap-3">
													<AntdBtn
														onClick={() => viewImage(index)}
														className="h-10 w-full sm:w-[100px] rounded-lg bg-[#0A4D68] text-white"
													>
														{t('download')}
													</AntdBtn>
													<AntdBtn
														onClick={() => deleteImage(index)}
														className="h-10 w-full sm:w-[100px] rounded-lg p-2 bg-red-500 text-white"
													>
														{t('delete')}
													</AntdBtn>
													{index === path.length - 1 && (
														<AntdBtn
															onClick={addNewRow}
															className="h-10 w-full sm:w-[100px] rounded-lg bg-[#0A4D68] text-white"
														>
															{t('add2')}
														</AntdBtn>
													)}
												</div>
											</div>
										)
									) : (
										<div
											key={index}
											className="flex flex-col w-full items-start gap-1 mb-5"
										>
											<div>
												<p className="font-bold">
													{t('photo')}-{index + 1}
												</p>
											</div>
											<div className="flex flex-col sm:flex-row items-center gap-3 ">
												<Input
													type="file"
													onChange={(e) => handleFileChange(e, index)}
													className="w-full"
												/>
												<AntdBtn
													onClick={() => uploadImg(index)}
													loading={image.imgLoading}
													className="h-10 w-full sm:w-[100px] rounded-lg bg-[#0A4D68] text-white"
												>
													{t('upload')}
												</AntdBtn>
												{image.path && (
													<>
														<AntdBtn
															onClick={() => viewImage(index)}
															className="h-10 w-full sm:w-[100px] rounded-lg bg-[#0A4D68] text-white"
														>
															{t('download')}
														</AntdBtn>
														<AntdBtn
															onClick={() => deleteImage(index)}
															className="h-10 w-full sm:w-[100px] rounded-lg p-2 bg-red-500 text-white"
														>
															{t('delete')}
														</AntdBtn>
													</>
												)}
												{index !== 0 && (
													<AntdBtn
														onClick={() => deleteRow(index)}
														className="h-10 w-full sm:w-[100px] rounded-lg p-2 bg-red-500 text-white"
													>
														{t('cancel')}
													</AntdBtn>
												)}
												{index === path.length - 1 && (
													<AntdBtn
														onClick={addNewRow}
														className="h-10 w-full sm:w-[100px] rounded-lg bg-[#0A4D68] text-white"
													>
														{t('add2')}
													</AntdBtn>
												)}
											</div>
										</div>
									),
								)}
							</div>
						</div>
						<div className="flex gap-3 flex-col lg:flex-row justify-end w-full col-span-2 ">
							<div className="flex gap-3">
								<Button btnStyle={{ backgroundColor: 'red' }} onClick={cancel}>
									{t('back')}
								</Button>
							</div>
							<Button
								btnStyle={{ width: 250 }}
								hoverBtnStyle={{ width: 250 }}
								onClick={allSaves}
								isLoading={isCommitLoading}
							>
								{t('save')}
							</Button>
						</div>
					</div>

					<div className="flex items-center gap-5 justify-between py-5 flex-col sm:flex-row">
						<div className="flex flex-col items-center gap-5">
							{getMe.Department2.Name === 'Boshqaruv' && (
								<Button
									btnStyle={{ backgroundColor: '#243AB5' }}
									onClick={() => policeExpenseRef.current?.open(DocEntry)}
								>
									{t('addPoliceExpense')}
								</Button>
							)}
						</div>
						<div className="flex flex-col items-center p-5 justify-center sm:flex-row gap-5 sm:gap-5 rounded-3xl bg-white drop-shadow-xl">
							<div className="flex gap-2 flex-col items-center">
								<Tag color="green" className=" text-sm">
									{t('course')}
								</Tag>
								<span className="font-bold text-zinc-900">
									{' '}
									{new Intl.NumberFormat('fr-FR')
										.format(dollar)
										.replace(',', '.')}{' '}
									UZS
								</span>
							</div>
							<div className="flex gap-2 flex-col items-center">
								<Tag color="red" className=" text-sm">
									{t('debt')}
								</Tag>
								<span className="font-bold text-zinc-900">
									{new Intl.NumberFormat('fr-FR', {
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									})
										.format(paymentSum - sumPay)
										.replace(',', '.')}{' '}
									USD
								</span>
							</div>
							<div className="flex gap-2 flex-col items-center">
								<Tag color="red" className="text-sm">
									{t('debtTotal')}
								</Tag>
								<span className="font-bold text-zinc-900">
									{new Intl.NumberFormat('fr-FR', {
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									})
										.format(debtTotal)
										.replace(',', '.')}{' '}
									USD
								</span>
							</div>

							{getMe?.Department2.Name !== 'Scoring' &&
							getMe?.Department2.Name !== 'Sotuv' ? (
								<div className="flex items-center gap-5">
									<Button
										btnStyle={{ backgroundColor: 'red' }}
										onClick={() => {
											acceptRef.current?.open()
										}}
									>
										{t('cancelInvoice')}
									</Button>
									<Button
										btnStyle={{ backgroundColor: '#243AB5' }}
										onClick={() => paymentRef.current?.open()}
										isLoading={isLoading || isLoading2 || dollarLoading}
										hoverBtnStyle={{ backgroundColor: '#243AB5' }}
									>
										{t('pay')}
									</Button>
								</div>
							) : (
								''
							)}
						</div>
					</div>
					<div>
						{data.Installmnt && tableData[0]?.visualInstNum < 1 && (
							<div className="flex items-center gap-5">
								<div className="flex gap-2 flex-col items-start">
									<p className={'font-medium text-zinc-600 text-sm mb-1'}>
										{t('firstMonth')}
									</p>
									<input
										className={
											'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-[170px]'
										}
										value={moment(tableData[0]?.DueDate).format('DD.MM.YYYY')}
										disabled={true}
									/>
								</div>
								<div className="flex gap-2 flex-col items-start">
									<p className={'font-medium text-zinc-600 text-sm mb-1'}>
										{t('payment')}
										{/* 										{t('firstPayingMonth')}
										 */}
									</p>
									<input
										className={
											'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-[80px]'
										}
										value={new Intl.NumberFormat('fr-FR')
											.format(Number(tableData[0]?.InsTotal))
											.replace(',', '.')}
										disabled={true}
									/>
								</div>
								<div className="flex gap-2 flex-col items-start">
									<p className={'font-medium text-zinc-600 text-sm mb-1'}>
										{t('paid')}
									</p>
									<input
										className={
											'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-[80px]'
										}
										value={new Intl.NumberFormat('fr-FR')
											.format(Number(tableData[0]?.PaidToDate))
											.replace(',', '.')}
										disabled={true}
									/>
								</div>
							</div>
						)}
					</div>
					<div className="flex gap-7 flex-col sm:flex-row">
						<div>
							<h1 className={'font-bold mb-3'}>{t('paymentPlan')}</h1>

							<table className="w-full text-sm text-left rtl:text-right text-black">
								<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
									<tr>
										<th scope="col" className="px-6 py-4">
											N
										</th>
										<th scope="col" className="px-6 py-4">
											{t('date')}
										</th>
										<th scope="col" className="px-6 py-4">
											{t('payment')}
										</th>
										<th scope="col" className="px-6 py-4">
											{t('paid')}
										</th>
									</tr>
								</thead>
								<tbody>
									{tableData.length > 0 ? (
										tableData
											.filter((v) => {
												return v.visualInstNum !== 0
											})
											.map((v, i) => {
												let a = v.DueDate.split('')
												a.splice(4, 0, '-')
												a.splice(7, 0, '-')
												a.join()
												return (
													<tr
														className="bg-white border-b  hover:bg-gray-50 "
														key={i}
													>
														<td className="px-6 py-4 font-medium text-gray-900 ">
															{+v?.visualInstNum}
														</td>
														<td className="px-6 py-4 whitespace-nowrap">
															{' '}
															{moment(v.DueDate).format('DD.MM.YYYY')}
														</td>
														<td className="px-6 py-4">
															{new Intl.NumberFormat('fr-FR', {
																minimumFractionDigits: 2,
																maximumFractionDigits: 2,
															})
																.format(Number(v.InsTotal))
																.replace(',', '.')}
														</td>
														<td className="px-6 py-4">
															{new Intl.NumberFormat('fr-FR', {
																minimumFractionDigits: 2,
																maximumFractionDigits: 2,
															})
																.format(Number(v.PaidToDate))
																.replace(',', '.')}
														</td>
													</tr>
												)
											})
									) : (
										<tr>
											<td colSpan="10">
												<Empty
													description={t('noInfo')}
													image={Empty.PRESENTED_IMAGE_SIMPLE}
												/>
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
						<div className="overflow-y-auto">
							<h1 className={'font-bold mb-3'}>{t('fact-invoice')}</h1>
							<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
								<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
									<tr>
										<th scope="col" className="px-6 py-4">
											N
										</th>
										<th scope="col" className="px-6 py-4">
											{t('date')}
										</th>
										<th scope="col" className="px-6 py-4">
											{t('paid')}
										</th>
										<th scope="col" className="px-6 py-4">
											{t('course')}
										</th>
										<th scope="col" className="px-6 py-4">
											{t('acctName')}
										</th>
										<th scope="col" className="px-6 py-4">
											{t('comment')}
										</th>
										<th scope="col" className="px-6 py-4">
											{t('action')}
										</th>
									</tr>
								</thead>
								<tbody>
									{tableInvoicePayments.length > 0 ? (
										tableInvoicePayments.map((v, i) => {
											const totalInUZS =
												Number(v.CashSum).toFixed(2) * Number(v.DocRate)
											return (
												<tr
													className="bg-white border-b  hover:bg-gray-50 "
													key={i}
												>
													<td className="px-8 py-6 font-medium text-gray-900 ">
														{' '}
														{Number(v.U_CashFlow) === 4 ||
														Number(v.U_CashFlow) === 5
															? v['U_CashFlow.Descr']
															: v?.visualInstNum}
													</td>

													<td className="px-6 py-4 whitespace-nowrap">
														{moment(v.DocDate).format('DD.MM.YYYY')}
													</td>
													<td className="px-6 py-4">
														{new Intl.NumberFormat('fr-FR', {
															minimumFractionDigits: 2,
															maximumFractionDigits: 2,
														})
															.format(Number(v.CashSum))
															.replace(',', '.')}{' '}
													</td>
													<td className="px-6 py-4">
														{new Intl.NumberFormat('fr-FR', {
															minimumFractionDigits: 2,
															maximumFractionDigits: 2,
														})
															.format(Number(v.DocRate))
															.replace(',', '.')}{' '}
													</td>

													<td className="px-6 py-4 ">{v.AcctName}</td>
													<td className="px-8 py-6 font-medium text-gray-900 ">
														{' '}
														{Number(v.U_CashFlow) === 4 ||
														Number(v.U_CashFlow) === 5
															? v.Comments
															: '-'}
													</td>
													<td className="px-6 py-4">
														<div className="flex items-center gap-2">
															<button
																className={
																	'whitespace-nowrap bg-[#0A4D68] text-white p-2 rounded-md disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-500 hover:bg-[#083D55] hover:text-white'
																}
																onClick={() => downloadCheck(i, v)}
																disabled={
																	isLoading || isLoading2 || dollarLoading
																}
															>
																<IoMdDownload />
															</button>
															<button
																className={
																	'whitespace-nowrap bg-[#0A4D68] text-white p-2 rounded-md disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-500 hover:bg-[#083D55] hover:text-white'
																}
																onClick={() => navigateToPrint(i, v, data)} // Pass 'i', 'v', and 'data' here
																disabled={
																	isLoading || isLoading2 || dollarLoading
																}
															>
																<IoIosPrint />
															</button>
														</div>
													</td>
													<div
														id={'check' + i}
														className={
															'hidden'
															// 'absolute -top-[100%] -right-[100%] w-[210mm]'
														}
													>
														<div className="w-[219px] p-1  border border-gray-300 rounded-md shadow-md">
															<p className={'mb-1 text-center'}>
																***************************
															</p>

															<div className="textAlign-center  mb-[1px] topCard">
																<img
																	src={logo}
																	className="w-[120px]"
																	alt={'check list in credo mobile'}
																/>
															</div>

															<p className="topTitle">
																{t('Квитанция платежа кредита')}
															</p>
															<div className="border-t border-black mb-[1px] card"></div>

															<div className="betweenCard2">
																<p className="title">{t('idNum')}</p>
																<p className="title2">{v.visualInstNum}</p>
															</div>

															<div className="betweenCard2">
																<p className="title">{t('IncomingId')} :</p>
																<p className="title2 ">{v['DocNum']}</p>
															</div>
															<div className="betweenCard2">
																<p className="title">{t('invoiceId')} :</p>
																<p className="title2 ">{invoiceDocNum}</p>
															</div>

															<div className="betweenCard2">
																<p className="title">{t('idClient')}:</p>
																<p className="title2 ">
																	{get(data, 'CardCode', '')}
																</p>
															</div>

															<div className="betweenCard2">
																<p className="title">{t('paymnetDate')}:</p>
																<p className="title2">
																	{`${moment(v.DocDate).format('DD.MM.YYYY')} ${v.U_Time ? moment(`${String(v.U_Time).padStart(4, '0').slice(0, 2)}:${String(v.U_Time).padStart(4, '0').slice(2)}`, 'HH:mm').format('HH:mm') : ''}`}
																</p>
															</div>

															<div className="betweenCard2">
																<p className="title">{t('paymentAmount')}:</p>
																<p className="title2">
																	{new Intl.NumberFormat('fr-FR', {
																		minimumFractionDigits: 2,
																		maximumFractionDigits: 2,
																	})
																		.format(totalInUZS)
																		.replace(',', '.')}
																</p>
															</div>
															<div className="betweenCard2">
																<p className="title">{t('debtTotal2')}:</p>
																<p className="title2">
																	{new Intl.NumberFormat('fr-FR', {
																		minimumFractionDigits: 2,
																		maximumFractionDigits: 2,
																	})
																		.format(debtTotal)
																		.replace(',', '.')}
																</p>
															</div>
															<div className="betweenCard2">
																<p className="title">{t('payersName')}:</p>
																<p className="title2">
																	{get(data, 'CardName', '-')}
																</p>
															</div>

															<div className="betweenCard2">
																<p className="title">{t('payersPhone')}:</p>
																<p className="title2">
																	{formatPhoneNumber(
																		formatPhoneNumber2(phoneNum),
																	)}
																</p>
															</div>
															<div className="betweenCard2">
																<p className="title">{t('productName')}</p>
																<p className="title2">
																	{get(data, 'Dscription', '-')}
																</p>
															</div>

															<div className="betweenCard2">
																<p className="title">Подпис/Imzo:</p>
															</div>

															<p className="title2 mb-1">
																Telegram kanalga ulanish uchun QR kod:
															</p>
															<div className="flex items-center justify-center">
																<img src={qr} alt="" className="w-[150px] " />
															</div>
															<div className="border-t border-black mb-[1px] card"></div>

															<div className="betweenCard2">
																<p className="title"> Aloqa uchun telefon</p>
																<p className="title2">71-200-30-07</p>
															</div>
															<p className={'mt-2 text-center'}>
																***************************
															</p>
														</div>
													</div>
												</tr>
											)
										})
									) : (
										<tr>
											<td colSpan="10">
												<Empty
													description={t('noInfo')}
													image={Empty.PRESENTED_IMAGE_SIMPLE}
												/>
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
					</div>
					<div className="flex items-center justify-end mt-8 mb-10">
						<Button btnStyle={{ backgroundColor: 'red' }} onClick={cancel}>
							{t('back')}
						</Button>

						<Button
							btnStyle={{ marginLeft: 20, width: 200 }}
							onClick={() => {
								if (window.location.hostname.toLowerCase().includes('istyle')) {
									navigate('/ru_download', {
										state: { data: downloadData, user: data, tableData },
									})
								} else {
									navigate('/download', {
										state: { data: downloadData },
									})
								}
							}}
						>
							{t('downloadContract')}
						</Button>
						{window.location.hostname.toLowerCase().includes('istyle') && (
							<Button
								btnStyle={{ marginLeft: 20, width: 225 }}
								onClick={() =>
									navigate('/uz_download', {
										state: { data: downloadData, user: data, tableData },
									})
								}
							>
								Шартномани юклаш
							</Button>
						)}
					</div>
				</div>
				<div className="shadow-md w-full   md:w-[28%] pt-5 border-[1px] border-[#DFE2E8]">
					<div className="p-4 shadow-md">{t('comment')} </div>
					<div className="p-4 h-[85vh] overflow-y-scroll">
						{comments.map((v, i) => {
							return (
								<div className="mb-4">
									<p className={'font-medium text-zinc-400 text-sm mb-1'}>
										{moment(get(v, 'U_CreateTime', '')).format(
											'DD-MM-YYYY HH:mm',
										)}
										{'  '}
										{get(v, 'Name', '')}
										{/* {isComment.loading && isComment.order === i && (
												<ClipLoader
													loading={true}
													size={10}
													aria-label="Loading Spinner"
													data-testid="loader"
												/>
											)} */}
									</p>

									<div className="inputCard2">
										<div className="p-3 pt-5 font-medium rounded-lg rounded-bl-none bg-[#29d2f5] text-white relative">
											{(getMe.Department2.Name === 'Boshqaruv' ||
												getMe.Department2.Name === 'Undiruvchi1' ||
												`${get(getMe, 'FirstName', '')} ${get(getMe, 'LastName', '')}` ===
													get(v, 'Name', '')) && (
												<button
													className="absolute top-1 right-1 z-10 cursor-pointer"
													onClick={() => deleteComments(get(v, 'Code', ''), i)}
												>
													<CloseIcon className="text-white w-[20px] " />
												</button>
											)}
											{get(v, 'U_Text', '')}
										</div>
									</div>
								</div>
							)
						})}
					</div>
				</div>
			</div>
			<>
				{dollarLoading ? null : (
					<PaymentModal
						getRef={(r) => (paymentRef.current = r)}
						onConfirm={(form) => {
							createPayment(form)
						}}
						onClose={() => {
							paymentRef.current?.close()
							getInvoicePayments()
						}}
						loading={isLoading}
						currencyRate={dollar}
						totalDebt={debtTotal + 1}
						security={`${get(data, 'U_Security', '-')}`}
					/>
				)}
				<AddPoliceOutgoingModal
					getRef={(r) => (policeExpenseRef.current = r)}
				/>
				<ErrorModal getRef={(r) => (errorRef.current = r)} />
				<AcceptCancelModal
					getRef={(r) => (acceptRef.current = r)}
					onConfirm={() => handleClose(DocEntry)}
					isLoading={isLoading3}
				/>
				<SuccessModal
					getRef={(r) => (successModalRef.current = r)}
					onConfirm={() => successModalRef.current?.close()}
				/>
				<SuccessModal
					getRef={(r) => {
						successRef.current = r
					}}
				/>
				<UpdateUser getRef={(ref) => (updateUserRef.current = ref)} />
				<NoDollar getRef={getNoDollarRef} />
			</>
		</Layout>
	)
}

export default ViewItem
