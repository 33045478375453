import { persistStore, persistReducer } from 'redux-persist'
import { configureStore } from '@reduxjs/toolkit'
import reducers from './reducers'
import thunk from 'redux-thunk'
import AsyncStorage from '@react-native-async-storage/async-storage'

const persistConfig = {
	key: 'root',
	storage: AsyncStorage,
	whitelist: [
		'main',
		'recovery',
		'cashPayment',
		'instalmentPayment',
		'calculator',
	],
}

const persistedReducer = persistReducer(persistConfig, reducers)
export const store = configureStore({
	reducer: persistedReducer,
	middleware: [thunk],
})

export const persistor = persistStore(store)
