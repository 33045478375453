import React, { memo, useEffect, useState, useRef } from 'react'
import CheckByPhoneModalStyle from './CheckByPhoneModalStyle'
import Modal from 'react-modal'
import { ErrorModal } from '../index'
import { get } from 'lodash'
import { customFuncsApi } from '../../../api'
import moment from 'moment'
import customMainApi from '../../../api/index'
import formatPhoneNumber from '../../../helpers/PhoneWithSpaces'
import { useTranslation } from 'react-i18next'
import { Empty } from 'antd'
const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		borderRadius: 15,
		border: 'none',
		maxHeight: '100vh',
		width: '85%',
	},
	overlay: {
		background: '#00000099',
	},
}

const CheckByPhoneModal = ({
	getRef = () => {},
	onConfirm = () => {},
	onClose = () => {},
	phones = '',
}) => {
	const { t } = useTranslation()

	const [isOpenModal, setIsOpenModal] = useState(false)
	const [data, setData] = useState([])
	const [userFields, setUserFields] = useState([])

	const errorModalRef = useRef()

	useEffect(() => {
		const ref = {
			open: (t) => {
				setIsOpenModal(true)
				getInvoceByPhoneNumber(t)
			},
			close: () => setIsOpenModal(false),
		}
		getRef(ref)
	}, [])
	useEffect(() => {
		userFieldsFn()
	}, [])

	const getInvoceByPhoneNumber = (phones) => {
		const phone = phones.split(',')
		const formattedPhones = phone.map((p) => `"${p.trim()}"`).join(',')

		try {
			customFuncsApi
				.get(`xsSql/getInvoicesByPhoneNumbers?phones=[${formattedPhones}]`)
				.then((res) => {
					const resData = get(res, 'data.value', [])
					setData(resData)
				})
		} catch (err) {
			errorModalRef.current?.open(JSON.parse(err.response.data).error.message)
		}
	}

	const userFieldsFn = () => {
		customMainApi.get('U_PROBLEM').then((res) => {
			setUserFields(JSON.parse(res.data).value)
		})
	}

	return (
		<Modal
			isOpen={isOpenModal}
			onRequestClose={() => setIsOpenModal(false)}
			style={customStyles}
			contentLabel="Example Modal"
			ariaHideApp={false}
		>
			<CheckByPhoneModalStyle>
				<div className=" w-full overflow-y-auto overflow-x-auto ">
					<table className="w-full  text-sm text-left rtl:text-right text-gray-500 ">
						<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
							<tr>
								<th scope="col" className="px-12 py-4">
									{t('clientName')}
								</th>
								<th scope="col" className="px-12 py-4">
									{t('product')}
								</th>
								<th scope="col" className="px-6 py-4">
									{t('status')}
								</th>
								<th scope="col" className="px-6 py-4">
									{t('phone')}
								</th>

								<th scope="col" className="px-6 py-4">
									{t('totalPrice')}
								</th>
								<th scope="col" className="px-6 py-4">
									{t('paid')}
								</th>

								<th scope="col" className="px-6 py-4">
									{t('dateOfSale')}
								</th>
							</tr>
						</thead>
						<tbody>
							{data.length > 0 ? (
								data.map((v, i) => {
									return (
										<tr
											key={i}
											// onClick={() => viewItem(v)}
											className="bg-white border-b  hover:bg-gray-50 "
										>
											{' '}
											<td className="px-6 py-4">{get(v, 'CardName', '')}</td>
											<td className="px-6 py-4">{get(v, 'Dscription', '')}</td>
											<td className="px-6 py-4">
												{get(v, 'U_Score.Name', '')}
											</td>
											<td className="px-6 py-4">
												{formatPhoneNumber(get(v, 'U_Telephone', '-'))}
											</td>
											<td className="px-6 py-4">
												{new Intl.NumberFormat('fr-FR', {
													minimumFractionDigits: 2,
													maximumFractionDigits: 2,
												})
													.format(get(v, 'DocTotal', '-'))
													.replace(',', '.')}
											</td>
											<td className="px-6 py-4">
												{new Intl.NumberFormat('fr-FR', {
													minimumFractionDigits: 2,
													maximumFractionDigits: 2,
												})
													.format(
														get(v, 'DocTotal', '-') - get(v, 'PaidToDate', '-'),
													)
													.replace(',', '.')}
											</td>
											<td className="px-6 py-4">
												{moment(get(v, 'DocDate', '-')).format('DD.MM.YYYY')}
											</td>
										</tr>
									)
								})
							) : (
								<tr>
									<td colSpan="10">
										<Empty
											description={t('noInfo')}
											image={Empty.PRESENTED_IMAGE_SIMPLE}
										/>
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
				<ErrorModal getRef={(r) => (errorModalRef.current = r)} />
			</CheckByPhoneModalStyle>
		</Modal>
	)
}

export default memo(CheckByPhoneModal)
