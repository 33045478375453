import React, { useEffect, useRef, useState } from 'react'
import Button from '../../components/Button'
import { customFuncsApi } from '../../api'
import _, { get } from 'lodash'
import Layout from '../../components/Layout'
import { ErrorModal } from '../../components/Modal'
import ClipLoader from 'react-spinners/ClipLoader'
import { isAxiosError } from 'axios'
import moment from 'moment'
import customMainApi from '../../api/index'
import { Empty } from 'antd'
import { useTranslation } from 'react-i18next'
import Styles from './AllProducts'
import { debouncedSet } from '../../utils/debounce'
import RenderTableHeader from '../../components/TableHeader/index'
import RenderTableRow from '../../components/TableRow/index'
import { checkValueToNotNull } from '../../utils/checkValue'
import { formatNumber } from '../../utils/formatNumber'

const UndiruvInstallPlan = () => {
	const errorRef = useRef()
	const inputRef = useRef(null)
	const { t } = useTranslation()

	const [isLoading, setIsLoading] = useState(false)
	const [cardCode, setCardCode] = useState('')
	const [imeiClient, setImeiClient] = useState('')
	const [phoneNum, setPhoneNum] = useState('')
	const [bp, setBp] = useState([])
	const [data, setData] = useState({
		data: [],
	})
	const [pagination, setPagination] = useState({
		page: 0,
	})
	const [sortConfig, setSortConfig] = useState({
		column: null,
		direction: null,
	})

	const search = (cardCode) => {
		if (!cardCode && !imeiClient && !phoneNum) {
			setData({
				data: [],
			})
			return
		}
		const sort = `${
			sortConfig.direction !== null
				? `&orderBy=[{"name": "${sortConfig.column}", "type" : "${sortConfig.direction}"}]`
				: ``
		}`
		setIsLoading(true)
		customFuncsApi
			.get(
				`XsSql/getInvoices?type='manyinst'&$skip=${pagination.page * 20}&CardCode=${checkValueToNotNull(cardCode)}&IntrSerial=${checkValueToNotNull(imeiClient)}&U_Telephone=${checkValueToNotNull(phoneNum)}${sort}`,
				{
					headers: {
						Prefer: 'odata.maxpagesize=20',
					},
				},
			)
			.then((res) => {
				const resData = res.data.value

				setData({
					data: [...resData],
				})
				setIsLoading(false)
			})
			.catch((err) => {
				if (isAxiosError(err))
					errorRef.current?.open(
						get(JSON.parse(err.response.data), 'error.message', ''),
					)
				setIsLoading(false)
			})
	}

	const getBusinessPartners = async (name = '') => {
		if (name.length <= 0) {
			setBp([])
			return
		}

		const data = await customMainApi
			.get(
				`BusinessPartners?$select=CardCode,CardName&$filter=contains(CardName,'${name}') and Frozen eq 'tNO' and CardType eq 'cCustomer'`,
			)
			.then((res) => {
				return JSON.parse(res.data).value
			})

		const selectElementData = data.map((item) => {
			return {
				value: item.CardCode,
				label: item.CardName,
			}
		})

		setBp(selectElementData)
	}

	const handleBusinessPartner = _.debounce((e) => {
		getBusinessPartners(e.target.value)
	}, 1000)

	const handleBusinessPartnerClick = (event, code) => {
		if (inputRef.current) {
			inputRef.current.value = event.target.innerText
		}
		setCardCode(code)
		search(code)
		setBp([])
	}

	useEffect(() => {
		search(cardCode)
	}, [cardCode, imeiClient, phoneNum, sortConfig])

	const viewItem = (v) => {
		const url = `/viewItem?data=${encodeURIComponent(JSON.stringify(v))}&DocEntry=${v.DocEntry}`
		window.open(url, '_blank')
	}

	const newDatas = () => {
		if (data.data.length < 20) {
			alert(t('noOtherInfo'))
		} else {
			setPagination((prev) => ({ ...prev, page: prev.page + 1 }))
		}
	}

	const oldData = () => {
		if (pagination.page > 0) {
			setPagination((prev) => ({ ...prev, page: prev.page - 1 }))
		} else {
			alert(t('noOtherInfo'))
		}
	}

	const handleImeiChange = (val) => {
		debouncedSet(val, setImeiClient)
	}

	const handlePhoneChange = (val) => {
		debouncedSet(val, setPhoneNum)
	}

	const handleSort = (column) => {
		let direction = 'ASC'
		if (sortConfig.column === column && sortConfig.direction === 'ASC') {
			direction = 'DESC'
		} else if (
			sortConfig.column === column &&
			sortConfig.direction === 'DESC'
		) {
			direction = null
		}
		setSortConfig({ column, direction })
	}

	return (
		<Layout>
			<Styles>
				<div className="container">
					<p className="productTitle">{t('credit')}</p>
					<div className="flex items-start gap-5 mt-10">
						<div className="searchable-select ">
							<p className={'font-medium text-zinc-600 text-sm mb-1'}>
								{t('client')}
							</p>
							<input
								className={
									'border-[1px] border-[#DFE2E9"] w-[300px] p-1 rounded-md '
								}
								ref={inputRef}
								type="text"
								onChange={handleBusinessPartner}
							/>
							{!!bp.length && (
								<div className="searchable-select-list">
									{bp.map((item) => {
										return (
											<div
												key={item.value}
												className="searchable-select-item"
												onClick={(event) =>
													handleBusinessPartnerClick(event, item.value)
												}
											>
												{item.label}
											</div>
										)
									})}
								</div>
							)}
						</div>
						<div className=" ">
							<p className={'font-medium text-zinc-600 text-sm mb-1'}>
								{t('imei')}
							</p>
							<input
								className={
									'border-[1px] border-[#DFE2E9"] w-[200px] p-1 rounded-md '
								}
								onChange={(e) => handleImeiChange(e.target.value)}
								type="text"
								// value={imeiClient}
							/>
						</div>

						<div className=" ">
							<p className={'font-medium text-zinc-600 text-sm mb-1'}>
								{t('phoneNumber')}
							</p>
							<input
								className={
									'border-[1px] border-[#DFE2E9"] w-[200px] p-1 rounded-md '
								}
								onChange={(e) => handlePhoneChange(e.target.value)}
								type="text"
								// value={phoneNum}
							/>
						</div>
					</div>

					{isLoading ? (
						<ClipLoader
							loading={true}
							size={75}
							aria-label="Loading Spinner"
							className={'loader'}
							data-testid="loader"
						/>
					) : (
						<>
							<div className="overflow-y-auto my-8">
								<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
									<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
										<tr>
											<RenderTableHeader title="clientCode" />
											<RenderTableHeader title="clientName" />
											<RenderTableHeader title="phoneNumber" />
											<RenderTableHeader title="imei" />
											<RenderTableHeader title="product" padding="px-12 py-4" />

											<RenderTableHeader
												title="totalPrice"
												sortColumn="DocTotal"
												handleSort={handleSort}
												sortConfig={sortConfig}
												isSortable
											/>
											<RenderTableHeader
												title="debtToday"
												sortColumn="DueToDate"
												handleSort={handleSort}
												sortConfig={sortConfig}
												isSortable
											/>
											<RenderTableHeader
												title="debtTotal"
												sortColumn="DebtTotal"
												handleSort={handleSort}
												sortConfig={sortConfig}
												isSortable
											/>
											<RenderTableHeader
												title="dateOfSale"
												sortColumn="DocDate"
												handleSort={handleSort}
												sortConfig={sortConfig}
												isSortable
											/>
											<RenderTableHeader title="lastComment" />
											<RenderTableHeader
												title="lastCommentDate"
												sortColumn="U_COMMENTS.U_CreateTime"
												handleSort={handleSort}
												sortConfig={sortConfig}
												isSortable
											/>
										</tr>
									</thead>
									<tbody>
										{data.data.length > 0 ? (
											data.data.map((v, i) => (
												<tr
													key={i}
													onClick={() => viewItem(v)}
													className="bg-white border-b  hover:bg-gray-50 "
												>
													<RenderTableRow
														value={get(v, 'CardCode', '-')}
														className={'font-medium text-gray-900'}
													/>
													<RenderTableRow
														value={get(v, 'CardName', '-')}
														className={'font-medium '}
													/>
													<RenderTableRow
														value={get(v, 'U_Telephone', '-')}
														className={'font-medium '}
													/>
													<RenderTableRow
														value={get(v, 'IntrSerial', '-')}
														className={'font-medium '}
													/>
													<RenderTableRow
														value={`${checkValueToNotNull(get(v, 'Dscription', ''))} ${checkValueToNotNull(get(v, 'U_Color', ''))} ${checkValueToNotNull(get(v, 'U_Condition', ''))} ${checkValueToNotNull(get(v, 'U_Memory', ''))}`}
													/>
													<RenderTableRow
														value={`${formatNumber(get(v, 'DocTotal', 0))} USD`}
														className={'font-medium '}
													/>
													<RenderTableRow
														value={`${formatNumber(get(v, 'DueToDate', 0))} USD`}
														className={'font-medium '}
													/>
													<RenderTableRow
														value={`${formatNumber(get(v, 'DebtTotal', 0))} USD`}
														className={'font-medium '}
													/>
													<RenderTableRow
														value={`${moment(get(v, 'DocDate', '-')).format(
															'DD.MM.YYYY',
														)} `}
														className={'font-medium '}
													/>
													<RenderTableRow
														value={get(v, 'U_COMMENTS.U_Text', '')}
													/>
													<RenderTableRow
														value={
															get(v, 'U_COMMENTS.U_CreateTime', '')
																? moment(
																		get(v, 'U_COMMENTS.U_CreateTime', ''),
																	).format('YYYY-MM-DD HH:mm')
																: ''
														}
													/>
												</tr>
											))
										) : (
											<tr>
												<td colSpan="10">
													<Empty
														description={t('noInfo')}
														image={Empty.PRESENTED_IMAGE_SIMPLE}
													/>
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>

							<div className="topCard">
								<div className="flex">
									<Button
										className={'btn'}
										onClick={oldData}
										btnStyle={{ marginRight: 10 }}
									>
										{'<'}
									</Button>
									<Button className={'btn'} onClick={newDatas}>
										{'>'}
									</Button>
								</div>
							</div>
						</>
					)}
				</div>
			</Styles>
			<>
				<ErrorModal
					getRef={(r) => {
						errorRef.current = r
					}}
				/>
			</>
		</Layout>
	)
}

export default UndiruvInstallPlan
