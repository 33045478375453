import React, { memo, useEffect, useState, useRef } from 'react'
import CheckByPhoneModalStyle from '../CheckByPhoneModal/CheckByPhoneModalStyle'
import Modal from 'react-modal'
import { ErrorModal } from '../index'
import { get } from 'lodash'
import api from '../../../api'
import ClipLoader from 'react-spinners/ClipLoader'
import ImeiModal from '../InventoryTansfersModal/ImeiModal'

import moment from 'moment'
import customMainApi from '../../../api/index'
import { useTranslation } from 'react-i18next'
import { Empty } from 'antd'
const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		borderRadius: 15,
		border: 'none',
		maxHeight: '100vh',
		width: '85%',
	},
	overlay: {
		background: '#00000099',
	},
}

const PurchaseModal = ({
	getRef = () => {},
	onConfirm = () => {},
	onClose = () => {},
	phones = '',
}) => {
	const { t } = useTranslation()

	const [isOpenModal, setIsOpenModal] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [data, setData] = useState([])
	const [userFields, setUserFields] = useState([])
	const [creator, setCreator] = useState('')

	const imeiModalRef = useRef()
	const errorModalRef = useRef()

	useEffect(() => {
		const ref = {
			open: (t) => {
				setIsOpenModal(true)
				getPurchaseInvoices(t)
			},
			close: () => setIsOpenModal(false),
		}
		getRef(ref)
	}, [])
	useEffect(() => {
		userFieldsFn()
	}, [])

	const getEmployess = (id) => {
		try {
			api(`EmployeesInfo(${id})`).then((res) => {
				const data = JSON.parse(res.data)
				setCreator(
					`${get(data, 'FirstName', '')} ${get(data, 'LastName', '')} `,
				)
			})
		} catch (err) {
			console.error('Error in Get Employees in Purchase Modal = ', err)
		}
	}

	const getPurchaseInvoices = (docNum) => {
		setIsLoading(true)
		try {
			api
				// ?$select=DocNum,DocEntry,CardName,DocDate,DocumentLines
				.get(`PurchaseInvoices(${docNum})`)
				.then((res) => {
					const resData = JSON.parse(res.data)
					setData(resData)
					setIsLoading(false)
					if (resData.DocumentsOwner) {
						getEmployess(resData.DocumentsOwner)
					} else {
						setCreator('')
					}
				})
		} catch (err) {
			errorModalRef.current?.open(JSON.parse(err.response.data).error.message)
			setIsLoading(false)
		}
	}

	const userFieldsFn = () => {
		customMainApi
			.get('UserFieldsMD', {
				params: {
					$filter: "TableName eq 'OINV'",
					$select: 'Name,ValidValuesMD,TableName',
				},
			})
			.then((res) => {
				setUserFields(JSON.parse(res.data).value)
			})
	}

	return (
		<Modal
			isOpen={isOpenModal}
			onRequestClose={() => setIsOpenModal(false)}
			style={customStyles}
			contentLabel="Example Modal"
			ariaHideApp={false}
		>
			<CheckByPhoneModalStyle>
				{isLoading ? (
					<div className="flex items-center justify-center">
						<ClipLoader
							loading={true}
							size={75}
							aria-label="Loading Spinner"
							className={'loader'}
							data-testid="loader"
						/>
					</div>
				) : (
					<>
						<div className="flex flex-col sm:flex-row items-center justify-between gap-5">
							<div className="flex flex-col items-start gap-3 w-full">
								<p>{t('docNum')}</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full sm:w-[200px]'
									}
									value={data.DocNum}
									disabled={true}
								/>
							</div>
							<div className="flex flex-col items-start gap-3 w-full">
								<p>{t('customer')}</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full sm:w-[200px]'
									}
									value={get(data, 'CardName', '')}
									disabled={true}
								/>
							</div>
							<div className="flex flex-col items-start gap-3 w-full">
								<p>{t('createdDoc')}</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full sm:w-[200px]'
									}
									value={creator}
									disabled={true}
								/>
							</div>
							<div className="flex flex-col items-start gap-3 w-full">
								<p>{t('purchaseDate')}</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full sm:w-[200px]'
									}
									value={moment(get(data, 'DocDate', '')).format('DD.MM.YYYY')}
									disabled={true}
								/>
							</div>
						</div>

						<div className=" w-full overflow-y-auto overflow-x-auto mt-10	mb-8 ">
							<table className="w-full  text-sm text-left rtl:text-right text-gray-500 ">
								<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
									<tr>
										<th scope="col" className="px-12 py-4">
											{t('product')}
										</th>
										<th scope="col" className="px-6 py-4">
											{t('quantity')}
										</th>
										<th scope="col" className="px-6 py-4">
											{t('imei')}
										</th>
										<th scope="col" className="px-6 py-4">
											{t('region2')}
										</th>

										<th scope="col" className="px-6 py-4">
											{t('price')}
										</th>
									</tr>
								</thead>
								<tbody>
									{data?.DocumentLines?.length > 0 ? (
										data?.DocumentLines?.map((v, i) => {
											const SerialNumbers = get(v, 'SerialNumbers', '')
											const Imei = Array.isArray(SerialNumbers)
												? SerialNumbers.map((imei, idx) => (
														<span key={`${i}-${idx}`} className="mr-2">
															{imei.InternalSerialNumber}
														</span>
													))
												: 'NoImei'
											const color =
												get(v, 'U_Color', '') !== ''
													? userFields
															?.find((item) => item.Name === 'Color')
															?.ValidValuesMD.find(
																(value) =>
																	get(v, 'U_Color', '') === value.Value,
															)?.Description || ''
													: ''

											const memory =
												get(v, 'U_Memory', '') !== ''
													? userFields
															?.find((item) => item.Name === 'Memory')
															?.ValidValuesMD.find(
																(value) =>
																	get(v, 'U_Memory', '') === value.Value,
															)?.Description || ''
													: ''

											const condition =
												get(v, 'U_Condition', '') !== ''
													? userFields
															?.find((item) => item.Name === 'Condition')
															?.ValidValuesMD.find(
																(value) =>
																	get(v, 'U_Condition', '') === value.Value,
															)?.Description || ''
													: ''
											return (
												<tr
													key={i}
													// onClick={() => viewItem(v)}
													className="bg-white border-b  hover:bg-gray-50 "
												>
													{' '}
													<td className="px-6 py-4">
														{get(v, 'ItemDescription', '')} {color} {memory}{' '}
														{condition}
													</td>
													<td
														className="px-6 py-4"
														// onClick={() => {
														// 	imeiModalRef.current.open(Imei)
														// }}
													>
														{get(v, 'Quantity', '-')}
													</td>
													<td className="px-6 py-4">{Imei}</td>
													<td className="px-6 py-4">
														{get(v, 'U_Region_Item', '')}
													</td>
													<td className="px-6 py-4">
														{new Intl.NumberFormat('fr-FR', {
															minimumFractionDigits: 2,
															maximumFractionDigits: 2,
														})
															.format(get(v, 'RowTotalSC', '-'))
															.replace(',', '.')}
													</td>
												</tr>
											)
										})
									) : (
										<tr>
											<td colSpan="10">
												<Empty
													description={t('noInfo')}
													image={Empty.PRESENTED_IMAGE_SIMPLE}
												/>
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
					</>
				)}

				<ErrorModal getRef={(r) => (errorModalRef.current = r)} />
				<ImeiModal getRef={(r) => (imeiModalRef.current = r)} />
			</CheckByPhoneModalStyle>
		</Modal>
	)
}

export default memo(PurchaseModal)
