import React, { memo, useEffect, useRef, useState } from 'react'
import PaymentModalStyle from './PaymentModalStyle'
import api, { customFuncsApi } from '../../../api'
import numberWithSpaces from '../../../helpers/numberWithSpaces'
import Modal from 'react-modal'
import { get } from 'lodash'
import Button from '../../Button'
import { ErrorModal, RefundModal, SuccessModal } from '../index'
import { useSelector } from 'react-redux'
import { roundCurrency } from '../../../utils'
import { useTranslation } from 'react-i18next'
import customMainApi from '../../../api/index'
import moment from 'moment-timezone'
import { Button as AntButton } from 'antd'

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		overflowY: 'scroll',
		maxHeight: '100vh',
	},
}

const PaymentModal = ({
	getRef = () => {},
	onConfirm = () => {},
	onClose = () => {},
	loading,
	currencyRate,
	totalDebt,
	refundShow = true,
	security,
}) => {
	const { t } = useTranslation()
	const { getMe } = useSelector((state) => state.main)
	const successModalRef = useRef()
	const errorRef = useRef()
	const formattedDate = moment().tz('Asia/Tashkent').format('YYYY-MM-DD')
	const refundRef = useRef()
	const [isOpenModal, setIsOpenModal] = useState(false)
	const [status, setStatus] = useState('')
	const [userFields2, setUserFields2] = useState([])

	const initDate = {
		dateC: formattedDate,
		time: moment().tz('Asia/Tashkent').format('HH:mm'),
		amountUzs: null,
		amountUsd: null,
		payme: null,
		numberCard: null, // payme card
		skidkaCard: '', //Skidka,militsiya,spisaniya
		amountSkidka: null, //Skidka,militsiya,spisaniya
		skidkaExternal: '',
		terminal: null,
		dollar: currencyRate,
		smsSend: false,
		amountUsdCard: null,
		securityName: null,
		isSkidka: '',
		isSpisaniya: '',
	}
	const [form, setForm] = useState({ ...initDate })
	const [isRefund, setIsRefund] = useState(false)
	const [accountsData, setAccountsData] = useState([])
	const [cashAccsDataDiscount, setCashAccDataDiscount] = useState([])
	const totalPayment = roundCurrency(
		(+form.amountUsd || 0) +
			(+form.amountUzs || 0) / form.dollar +
			(+form.payme || 0) / form.dollar +
			(+form.terminal || 0) / form.dollar +
			(+form.amountUsdCard || 0) +
			(+form.amountSkidka || 0),
	)

	const isDisabled = form.amountSkidka && !form.skidkaCard ? true : false

	useEffect(() => {
		const ref = {
			open: () => {
				setIsOpenModal(true)
				setForm({ ...initDate })
				getIclouds(security)
			},
			close: () => setIsOpenModal(false),
		}
		getRef(ref)
	}, [])

	const handleRefund = ({ DocDate, DocRate, Type, CashSumUzs, CashSumUsd }) => {
		setIsRefund(true)
		customFuncsApi
			.post(`addMoneyChange`, {
				DocDate: DocDate,
				Type: Type,
				DocRate: DocRate,
				CashSumUzs: +CashSumUzs,
				CashSumUsd: +CashSumUsd,
				CashUzsAccount: getMe.U_CashUzsAccount,
				CashUsdAccount: getMe.U_CashAccount,
			})
			.then(() => {
				refundRef.current?.clear()
				refundRef.current?.close()
				successModalRef.current?.open(t('paymentSuccess'))
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
			.finally(() => {
				setIsRefund(false)
			})
	}

	const accountsSpend = [
		{
			Name: 'Скидка',
			Code: '4',
		},
		{
			Name: 'Списание',
			Code: '2',
		},
	]

	useEffect(() => {
		api
			.get(
				"ChartOfAccounts?$filter=startswith(Code, '57') and AcctCurrency eq 'UZS' and ExternalCode eq '1'&$select=Code,Name,AcctCurrency",
				{
					headers: {
						Prefer: 'odata.maxpagesize=10000',
					},
				},
			)
			.then((res) => {
				setAccountsData(JSON.parse(res.data).value)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}, [])

	useEffect(() => {
		userFieldsFn()
		cashAccountsDiscount()
	}, [])

	const getIclouds = (code) => {
		if (code !== 'null' && code !== '' && code !== null && code !== '-') {
			customMainApi
				.get(`Security('${code}')`, {
					params: {
						$select:
							'Code,Name,U_Whichtelephone,U_Password,U_Status,U_TypeAccount',
					},
					headers: {
						Prefer: 'odata.maxpagesize=100',
					},
				})
				.then((res) => {
					setStatus(JSON.parse(res.data)?.U_Status)
				})
		}
	}

	const userFieldsFn = () => {
		customMainApi
			.get('UserFieldsMD', {
				params: {
					$filter: "TableName eq '@Security'",
				},
			})
			.then((res) => {
				setUserFields2(JSON.parse(res.data).value)
			})
	}

	const handleConfirm = () => {
		const payment = parseFloat(totalPayment)
		const debt = parseFloat(totalDebt)
		console.log(Number(totalDebt))
		console.log('payment=', payment)
		console.log('debt =', debt)
		// if (payment > totalDebt) {
		// 	errorRef.current?.open(t('biggerThanDebt'))
		// } else if (
		// Number(form.dollar) > Number(currencyRate) + 501 ||
		// Number(form.dollar) < Number(currencyRate) - 501
		// ) {
		// errorRef.current?.open(t('cantEnterBigger', { currencyRate }))
		// } else {
		onConfirm(
			{
				DocDate: moment
					.tz(`${form.dateC}`, 'Asia/Tashkent')
					.format('YYYY-MM-DD'),
				Time: form.time,
				CurrencyRate: form.dollar,
				AmountUsd: form.amountUsd,
				AmountUzs: form.amountUzs,
				AmountPayme: form.payme,
				NumberCard: form.numberCard,
				AmountSkidka: form.amountSkidka,
				SkidkaCard: form.skidkaCard,
				Terminal: form.terminal,
				smsSend: form.smsSend,
				AmountUsdCard: form.amountUsdCard,
				isSkidka: form.isSkidka,
				isSpisaniya: form.isSpisaniya,
			},
			console.log({
				DocDate: moment
					.tz(`${form.dateC}`, 'Asia/Tashkent')
					.format('YYYY-MM-DD'),
				Time: form.time,
				CurrencyRate: form.dollar,
				AmountUsd: form.amountUsd,
				AmountUzs: form.amountUzs,
				AmountPayme: form.payme,
				NumberCard: form.numberCard,
				AmountSkidka: form.amountSkidka,
				SkidkaCard: form.skidkaCard,
				Terminal: form.terminal,
				smsSend: form.smsSend,
				AmountUsdCard: form.amountUsdCard,
				isSkidka: form.isSkidka,
				isSpisaniya: form.isSpisaniya,
			}),
		)
		// }
	}

	const cashAccountsDiscount = async () => {
		const data = await api(
			`ChartOfAccounts?$select=Code,ExternalCode,Name&$filter=startswith(Code, '94') and (ExternalCode eq '4' or ExternalCode eq '2')`,
			{
				headers: {
					Prefer: 'odata.maxpagesize=10000',
				},
			},
		).then((res) => {
			return JSON.parse(res.data).value
		})
		setCashAccDataDiscount(data)
	}

	return (
		<Modal
			isOpen={isOpenModal}
			onRequestClose={() => {
				setIsOpenModal(false)
				setForm({ ...initDate })
			}}
			style={customStyles}
			contentLabel="Example Modal"
			ariaHideApp={false}
		>
			<PaymentModalStyle>
				<div className="w-full">
					<h2 className="text-lg font-bold text-zinc-900 text-center mb-6">
						{t('addPayment')}
					</h2>
					<div className="grid grid-cols-1 gap-4">
						<div className="flex flex-col md:flex-row gap-4 justify-between w-full">
							{get(getMe, 'Department2.Name', '') === 'Boshqaruv' ||
							get(getMe, 'Department2.Name', '') === 'Undiruv' ||
							get(getMe, 'Department2.Name', '') === 'Undiruvchi1' ||
							get(getMe, 'Department2.Name', '') === 'DokonManager' ||
							get(getMe, 'Department2.Name', '') === 'Sotuv' ||
							get(getMe, 'Department2.Name', '') === 'Cashier' ? (
								<div className="flex flex-col items-start gap-3 w-full">
									<p>{t('date')}</p>
									<input
										className={
											'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full sm:w-auto'
										}
										type={'date'}
										placeholder={t('date')}
										defaultValue={form.dateC}
										onChange={(v) =>
											setForm((prevValue) => ({
												...prevValue,
												dateC: v.target.value,
											}))
										}
									/>
								</div>
							) : (
								''
							)}
							<div className="flex flex-col items-start gap-3 w-full">
								<p>{t('course')}</p>
								<input
									className="border-[1px]  border-[#DFE2E9] p-2 rounded-md w-full sm:w-auto"
									placeholder={t('course')}
									value={form.dollar}
									onChange={(v) => {
										setForm((prevValue) => ({
											...prevValue,
											dollar: v.target.value,
										}))
									}}
								/>
							</div>
						</div>
						{get(getMe, 'Department2.Name', '') !== 'Undiruv' && (
							<input
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								placeholder={t('cashUsd')}
								onChange={(v) =>
									setForm((prevValue) => ({
										...prevValue,
										amountUsd: v.target.value.includes(',')
											? v.target.value.replace(',', '.')
											: v.target.value,
									}))
								}
							/>
						)}

						<input
							className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
							placeholder={t('cardUsd')}
							onChange={(v) =>
								setForm((prevValue) => ({
									...prevValue,
									amountUsdCard: v.target.value.includes(',')
										? v.target.value.replace(',', '.')
										: v.target.value,
								}))
							}
						/>
						{get(getMe, 'Department2.Name', '') !== 'Undiruv' && (
							<input
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								placeholder={t('cashUzs')}
								onChange={(v) =>
									setForm((prevValue) => ({
										...prevValue,
										amountUzs: v.target.value.includes(',')
											? v.target.value.replace(',', '.')
											: v.target.value,
									}))
								}
							/>
						)}
						<div className="flex flex-col md:flex-row gap-4">
							<input
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md flex-1 md:flex-1'
								}
								placeholder={t('paymeCard')}
								onChange={(v) =>
									setForm((prevValue) => ({
										...prevValue,
										payme: v.target.value.includes(',')
											? v.target.value.replace(',', '.')
											: v.target.value,
									}))
								}
							/>
							<div className="relative">
								<input
									type="text"
									list="productName"
									placeholder={t('cardNum')}
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md flex-1/2 w-[200px]'
									}
									value={form.numberCard}
									onChange={(v) =>
										setForm((prevValue) => ({
											...prevValue,
											numberCard: v.target.value,
										}))
									}
								/>
								<datalist id="productName">
									{accountsData.map((v, i) => (
										<option
											key={i}
											label={`${get(v, 'Name', '')} , ${get(v, 'Code', '')}`}
											value={get(v, 'Code', '')}
										/>
									))}
								</datalist>
							</div>
						</div>

						{get(getMe, 'Department2.Name', '') !== 'Undiruv' && (
							<input
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								placeholder={t('terminal')}
								onChange={(v) =>
									setForm((prevValue) => ({
										...prevValue,
										terminal: v.target.value.includes(',')
											? v.target.value.replace(',', '.')
											: v.target.value,
									}))
								}
							/>
						)}
					</div>

					<div className="flex flex-col md:flex-row gap-4 mt-4">
						<input
							className={
								'border-[1px] border-[#DFE2E9"] bg-blue-100 p-2 h-10 rounded-md flex-1 md:flex-1 '
							}
							placeholder={`${t('discount')} USD`}
							value={form.amountSkidka}
							onChange={(v) => {
								console.log(v.target.value)
								setForm((prevValue) => ({
									...prevValue,
									amountSkidka: v.target.value.includes(',')
										? v.target.value.replace(',', '.')
										: v.target.value,
								}))
							}}
						/>
						<div className="flex flex-col ">
							<select
								className={
									'border-[1px] border-[#000"] bg-blue-100 p-2 rounded-md w-[150px] '
								}
								value={form.skidkaExternal}
								onChange={(e) => {
									setForm((prevValue) => ({
										...prevValue,
										skidkaExternal: e.target.value,
										skidkaCard: '',
										isSpisaniya: e.target.value === '2' ? 'Yes' : '',
										isSkidka: e.target.value === '4' ? 'Yes' : '',
									}))
								}}
							>
								<option value={''} disabled>
									{t('typeDiscount')}
								</option>
								{accountsSpend.map((value) => (
									<option value={value.Code}>{value.Name}</option>
								))}
							</select>
							{form.amountSkidka && !form.skidkaExternal && (
								<span className="text-red-500 mt-2">
									{t('chooseDiscountType')}
								</span>
							)}
						</div>
						<div className="flex flex-col ">
							<select
								className={
									'border-[1px] border-[#000"] bg-blue-100 p-2 rounded-md min-w-[250px] '
								}
								value={form.skidkaCard}
								onChange={(e) => {
									setForm((prevValue) => ({
										...prevValue,
										skidkaCard: e.target.value,
									}))
								}}
								disabled={!form.skidkaExternal}
							>
								<option value={''} disabled>
									{t('chooseCash')}
								</option>
								{cashAccsDataDiscount
									.filter((value) => value.ExternalCode === form.skidkaExternal)
									.map((value) => (
										<option
											value={value.Code}
										>{`${value.Code} - ${value.Name}`}</option>
									))}
							</select>
							{form.amountSkidka && form.skidkaExternal && !form.skidkaCard && (
								<span className="text-red-500 mt-2">{t('chooseCash')}</span>
							)}
						</div>
					</div>

					<div className={'my-7'}>
						<label
							className={
								'font-medium text-zinc-600 text-[14px] mb-1 flex flex-col gap-2 items-start'
							}
						>
							{t('totalPayment')}
							<input
								value={numberWithSpaces(totalPayment) + ' USD'}
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-1/2'
								}
							/>
						</label>
					</div>
					<div className="flex items-center flex-col sm:flex-row gap-5 justify-between">
						<div className="flex items-center gap-3">
							<p>{t('sendSms')} :</p>
							<input
								type="checkbox"
								placeholder={t('sendSms')}
								onChange={(v) =>
									setForm((prevValue) => ({
										...prevValue,
										smsSend: v.target.checked,
									}))
								}
							/>
						</div>
						<div className="flex   items-start gap-3">
							<select
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full '
								}
								value={status}
								onChange={(v) => setStatus(v.target.value)}
							>
								{' '}
								<option value={''} disabled>
									{t('Securitystatus')}
								</option>
								{userFields2
									?.find((item) => item.Name === 'Status')
									?.ValidValuesMD.map((value) => (
										<option value={value.Value}>{value.Description}</option>
									))}
							</select>
						</div>
					</div>

					{refundShow && (
						<div>
							<Button
								btnStyle={{ backgroundColor: '#243AB5' }}
								onClick={() => refundRef.current?.open()}
								hoverBtnStyle={{ backgroundColor: '#243AB5' }}
								className="my-4"
							>
								{t('refund')}
							</Button>
						</div>
					)}

					<div className="flex items-center justify-between flex-col sm:flex-row gap-5 mt-5">
						<AntButton
							type="button"
							className="h-10 bg-red-600 text-white hover:bg-red-500 w-[80px] h-10"
							onClick={() => {
								onClose()
								setForm({ ...initDate })
							}}
						>
							{t('no')}
						</AntButton>
						<AntButton
							type="button"
							className="h-10 bg-green-700 text-white hover:bg-green-600 w-[80px] h-10 disabled:opacity-50 disabled:cursor-not-allowed"
							onClick={handleConfirm}
							disabled={loading || isDisabled}
							loading={loading}
						>
							{t('yes')}
						</AntButton>
					</div>
				</div>
				<RefundModal
					currencyRate={currencyRate}
					getRef={(r) => (refundRef.current = r)}
					onConfirm={(form) => handleRefund(form)}
					onClose={() => refundRef.current?.close()}
					isLoading={isRefund}
				/>
				<SuccessModal getRef={(r) => (successModalRef.current = r)} />
				<ErrorModal getRef={(r) => (errorRef.current = r)} />
			</PaymentModalStyle>
		</Modal>
	)
}

export default memo(PaymentModal)
