import { createSlice } from '@reduxjs/toolkit'

export const calculator = createSlice({
	name: 'calculator',
	initialState: {
		dollarRate: 0,
		usdValue: 0,
		total: 0,
		isModalOpen: false,
	},
	reducers: {
		setDollarRate: (state, action) => {
			state.dollarRate = action.payload
		},
		setUsdValue: (state, action) => {
			state.usdValue = action.payload
		},
		setTotal: (state, action) => {
			state.total = action.payload
		},
		setIsModalOpen: (state, action) => {
			state.isModalOpen = action.payload
		},
	},
})
