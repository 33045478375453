import { useEffect } from 'react'

const useEnterKey = (callback) => {
	return useEffect(() => {
		const handleKeyUp = (event) => {
			if (event.key === 'Enter') {
				callback()
			}
		}

		document.addEventListener('keyup', handleKeyUp)
		return () => {
			document.removeEventListener('keyup', handleKeyUp)
		}
	}, [callback])
}

export default useEnterKey
