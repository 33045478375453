import React, { useEffect, useRef, useState } from 'react'
import AllProductsStyle from '../AllProducts/AllProducts'
import { customFuncsApi } from '../../api'
import { get } from 'lodash'
import Layout from '../../components/Layout'
import { ErrorModal } from '../../components/Modal'
import ClipLoader from 'react-spinners/ClipLoader'
import { isAxiosError } from 'axios'
import { useSelector } from 'react-redux'
import ReportsModal from '../../components/Modal/ReportsModal/index'
import { Empty, Button as AntButton } from 'antd'
import { useTranslation } from 'react-i18next'
import useEnterKey from '../../hooks/useEnterKey'

const WhsReports = () => {
	const { getMe } = useSelector((state) => state.main)

	const errorRef = useRef()
	const viewRef = useRef()
	const { t } = useTranslation()

	const [isLoading, setIsLoading] = useState(false)
	const [imeiClient, setImeiClient] = useState('')
	const [product, setProduct] = useState('')
	const [data, setData] = useState([])
	const [currentPage, setCurrentPage] = useState(0)

	const search = () => {
		setIsLoading(true)
		// &$skip=${currentPage}
		// const url = `XsSql/getItemsByWarehouse?ItemName=${product}&isOnHand=true&IntrSerial=${imeiClient}${get(getMe, 'U_Warehouse', '') ? `&whsCode='${get(getMe, 'U_Warehouse', '')}'` : ''}${get(getMe, 'Department2.Name', '') === 'Boshqaruv' ? `&includeOtherWarehousesAfter=true` : ''}`
		const url = 'XsSql/getItemsByWarehouse'
		customFuncsApi
			.get(url, {
				headers: {
					Prefer: 'odata.maxpagesize=2000',
				},
			})
			.then((res) => {
				const resData = res.data.value || []
				setData(resData)
				setIsLoading(false)
			})
			.catch((err) => {
				if (isAxiosError(err)) errorRef.current?.open(get(err, 'message', ''))
				setIsLoading(false)
			})
	}

	useEffect(() => {
		search()
	}, [currentPage])

	const viewItem = (v) => {
		viewRef.current?.open(v)
	}

	const handleSearch = () => {
		search()
	}

	useEnterKey(() => handleSearch())

	return (
		<Layout>
			<AllProductsStyle>
				<div className="container">
					<p className="productTitle">{t('whsReports')}</p>
					<div className="flex items-center justify-between flex-col sm:flex-row gap-5 mt-5">
						<div className="flex items-center gap-5 flex-col sm:flex-row w-full">
							<input
								className={
									'border-[1px] border-[#d9d9d9"] p-2 rounded-md w-full sm:w-auto'
								}
								type="text"
								placeholder={t('productName')}
								defaultValue={product}
								onChange={(v) => setProduct(v.target.value)}
							/>
							<input
								className={
									'border-[1px] border-[#d9d9d9"] p-2 rounded-md w-full sm:w-auto'
								}
								type="text"
								placeholder={t('imei')}
								defaultValue={imeiClient}
								onChange={(v) => {
									setImeiClient(v.target.value)
								}}
							/>
						</div>

						<AntButton
							className="bg-[#0A4D68] text-white rounded-md w-full sm:w-[100px] h-10"
							onClick={handleSearch}
							loading={isLoading}
						>
							{t('search')}
						</AntButton>
					</div>

					{isLoading ? (
						<ClipLoader
							loading={true}
							size={75}
							aria-label="Loading Spinner"
							className={'loader'}
							data-testid="loader"
						/>
					) : (
						<>
							<div className="overflow-y-auto mb-8 mt-10">
								<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
									<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
										<tr>
											<th scope="col" className="px-6 py-4">
												{t('productCode')}
											</th>
											<th scope="col" className="px-6 py-4">
												{t('product')}
											</th>
											<th scope="col" className="px-6 py-4">
												{t('whsName')}
											</th>
										</tr>
									</thead>
									<tbody>
										{data.length > 0 ? (
											data.map((v, i) => {
												// Check if warehouse name has changed from previous item
												const prevWhsName = i > 0 ? data[i - 1].WhsName : null
												const currentWhsName = get(v, 'WhsName', '-')
												const warehouseChanged =
													prevWhsName !== currentWhsName && i > 0

												return (
													<React.Fragment key={i}>
														{warehouseChanged && (
															<tr className="border-t-2 border-gray-700">
																<td colSpan="3" className="p-0"></td>
															</tr>
														)}
														<tr
															onClick={() => viewItem(v)}
															className="bg-white border-b hover:bg-gray-50"
														>
															<td
																scope="row"
																className="px-6 py-4 font-medium text-gray-900"
															>
																{get(v, 'ItemCode', '-')}
															</td>
															<td className="px-6 py-4">
																{get(v, 'ItemName', '')} {get(v, 'U_Color', '')}{' '}
																{get(v, 'U_Memory', '')}{' '}
																{get(v, 'U_Condition', '')}
															</td>
															<td
																scope="row"
																className="px-6 py-4 font-medium text-gray-900"
															>
																{currentWhsName}
															</td>
														</tr>
													</React.Fragment>
												)
											})
										) : (
											<tr>
												<td colSpan="10">
													<Empty
														description={t('noInfo')}
														image={Empty.PRESENTED_IMAGE_SIMPLE}
													/>
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
							{/* <div className="topCard">
								<div className="flex items-center gap-2 ">
									<AntButton
										disabled={currentPage === 0}
										className={'btn'}
										onClick={oldData}
									>
										{'<'}
									</AntButton>
									<p>{currentPage / 20 + 1}</p>
									<Button
										disabled={data.length < 20}
										className={'btn'}
										onClick={newDatas}
									>
										{'>'}
									</Button>
								</div>
							</div> */}
						</>
					)}
				</div>
			</AllProductsStyle>
			<>
				<ReportsModal
					getRef={(ref) => (viewRef.current = ref)}
					onUpdated={() => search(data.currentPage, data.data)}
				/>
				<ErrorModal
					getRef={(r) => {
						errorRef.current = r
					}}
				/>
			</>
		</Layout>
	)
}

export default WhsReports
