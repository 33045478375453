import React from 'react'

const RenderTableRow = ({
	value,
	padding = 'px-6 py-4',
	className,
	textAlign = 'text-center',
}) => {
	return <td className={`${padding} ${className} ${textAlign}`}>{value}</td>
}

export default RenderTableRow
